<div aria-modal="true" class="relative z-50" role="dialog">

  <div (click)="drawerService.close()"
       (keyup.enter)="emulateClick($event)"
       class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20"
       tabindex="0"
  >


    <!--
      Command palette, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    -->
    <div (click)="$event.stopPropagation()"
         (keyup.enter)="emulateClick($event)"
         class="mx-auto max-w-xl max-h-[100%] transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
         tabindex="0">

      <div class="relative bg-beige rounded-lg m-7">
        <svg aria-hidden="true" class="pointer-events-none absolute top-4 left-4 h-8 w-8 text-gray-400"
             fill="currentColor" viewBox="0 0 20 20">
          <path clip-rule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                fill-rule="evenodd"/>
        </svg>
        <input #input (keyup)="filter.set(input.value.trim())" aria-controls="options"
               aria-expanded="false" cdkTrapFocus
               cdkTrapFocusAutoCapture
               class="h-16 w-full border-0 bg-transparent pl-16 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-md" placeholder="{{'searchOffer'|translate}}..." role="combobox"
               type="search">
      </div>

      <!-- Results, show/hide based on command palette state -->
      @if (filter()) {
        <ul class="max-h-[80vh] scroll-py-3 overflow-y-auto p-3" id="options" role="listbox">
          @for (product of products(); track product.id) {
            <!-- Active: "bg-gray-100" -->
            <li [routerLink]="['product', product.slug]"
                (click)="drawerService.close()"
                (keyup.enter)="emulateClick($event)"
                tabindex="0"
                class="group flex items-center gap-4 select-none rounded-xl p-3 cursor-pointer">
              <div class="basis-1/5 aspect-1 relative rounded-lg bg-gray-100">
                @if (siteService.images(product.id)[0]; as image) {
                  <img class="object-cover rounded-lg"
                       fill="" sizes="10vw"
                       [alt]="image.alt"
                       [ngSrc]="image.media">
                } @else {
                  <img class="object-cover rounded-lg"
                       fill="" sizes="10vw"
                       [alt]="product.name"
                       ngSrc="assets/images/placeholder.webp">
                }
              </div>
              <div class="relative basis-4/5">
                <div class="grid grid-cols-12">
                  <h3 class="text-lg uppercase font-semibold text-bleuCaliceo-300 col-span-8">
                    {{ 'product/' + product.id + '/name' | translate }}
                  </h3>

                  <div class="text-right col-span-4">
                    @if (price(product).fullPrice) {
                      <span
                        class="uppercase py-2 px-4 bg-vertCaliceo-500 text-white text-sm font-medium rounded">
                    {{ 'promo' | translate }}
                  </span>
                    }
                  </div>

                </div>
                <div class="mt-5 flex justify-between items-center">
                  <div class="flex flex-col">
                    @if (isGroup(product)) {
                      <span class="text-sm">{{ 'startingFrom' | translate }}</span>
                    }

                    @if (price(product); as p) {
                      <div class="font-semibold text-xl flex md:flex-row flex-col">
                        @if (p.fullPrice) {
                          <span class="mr-4 text-bleuCaliceo-300 line-through">
                            {{ p.fullPrice | currency }}
                          </span>
                        }
                        <span
                          [class.text-vertCaliceo-500]="p.fullPrice"
                          [class.text-bleuCaliceo-300]="!p.fullPrice">
                          {{ p.price | currency }}
                        </span>
                      </div>
                    }
                  </div>
                  <a appHoverGradient
                     [routerLink]="['/product', product.slug]"
                     class="w-40 px-6 text-center text-sm">
                    {{ 'learnMore' | translate }}
                  </a>

                </div>
              </div>
            </li>
            <div class="w-4/5 bg-gray-200 my-4 mx-auto h-[1px]"></div>

          } @empty {
            <li class="pb-14 pt-6 px-6 text-center text-sm sm:px-14">
              <svg class="mx-auto h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
              </svg>
              <p class="mt-4 font-semibold text-gray-900">{{ 'noResultsFound'|translate }}</p>
              <p class="mt-2 text-gray-500">{{ 'noResultsFound.message'|translate }}</p>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>
