<!--Header -->
<header class="relative bg-cover bg-center">
  @if (headerService.type() !== 'minimal') {
    <div class="overflow-hidden w-full h-full absolute top-0 z-[-1]">
      @if (!siteService.center() && globalService.landing().headerDisplayed === 'video') {
        <app-video fill="cover" [video]="{video: globalService.landing().headerVideo, background: true}"/>
      } @else {
        @for (image of images(); track image; let i = $index) {
          <img [alt]="image.alt"
               [ngSrc]="image.media"
               sizes="100vw"
               fill=""
               priority
               [class.animate-zoom-in]="animatedImageIndex.includes(i)"
               [ngClass]="i === animatedImageIndex[1] ? 'opacity-100' : 'opacity-0'"
               class="absolute object-cover top-0 left-0 w-full h-full transition-opacity duration-1000">
        }
      }
    </div>
  }
  <!--Navigation-->
  <div
    [ngClass]="{
    'pb-20 pt-3' : headerService.type() !== 'minimal'
     }">
    <nav class="layout border-gray-200 flex items-center justify-center md:justify-between py-6">
      <!--Logo-->
      <a class="flex items-center justify-center space-x-3 rtl:space-x-reverse" routerLink="/">
        <!--Needing to use @if instead of [ngSrc]= _ ? _ : _  because of an NgSrc UpdateImage reliance on window SSR bug -->
        @if (headerService.type() === 'minimal') {
          @if (giftMode()) {
            <img alt="{{'caliceo' | translate}}" class="h-16 lg:h-16 w-auto"
                 ngSrc="assets/images/logo-blue.svg"
                 priority
                 width="300"
                 height="76">
          } @else {
            <img alt="{{'caliceo' | translate}}" class="h-16 lg:h-16 lg:w-auto"
                 ngSrc="assets/images/logo-blue.svg"
                 priority
                 width="300"
                 height="76">
          }
        } @else {
          <ng-container *ngTemplateOutlet="logoWhite"></ng-container>

        }
        <span class="sr-only">Homepage</span>
      </a>
      <!--Search block-->
      @if (headerService.search()) {
        <button class="relative group ml-auto flex flex-col items-center"
                aria-labelledby="search"
                (click)="drawerService.open({component: SearchComponent, inputs: undefined, style: 'transparent'})"
                [ngClass]="textColorClasses()">
          <svg class="h-10 w-10 block m-auto object-cover p-0.5"
               [ngClass]="svgColorClasses()"
               viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.85">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M14.5776 14.5419C15.5805 13.53 16.2 12.1373 16.2 10.6C16.2 7.50721 13.6928 5 10.6 5C7.50721 5 5 7.50721 5 10.6C5 13.6928 7.50721 16.2 10.6 16.2C12.1555 16.2 13.5628 15.5658 14.5776 14.5419ZM14.5776 14.5419L19 19"
                stroke-linecap="round" stroke-linejoin="round"></path>
            </g>
          </svg>
          <span class="text-xs -mt-1">{{ 'search' | translate }}</span>
        </button>
      }
      <!--Navbar icons -->
      <div class="bg-white fixed left-0 right-0 bottom-0 flex items-center z-30 py-2 border-t border-gray-200 divide-x
              md:bg-transparent md:static md:flex md:py-0 md:border-0 md:border-transparent md:divide-x-0">
        <!--Go to center-->
        <div appAutoClose="always" appHover class="basis-0 md:basis-auto grow md:relative">
          <button
            [ngClass]="textColorClasses()"
            class="group mx-auto flex flex-col items-center md:py-2 md:px-3 hover:bg-white hover:bg-opacity-20 md:rounded-lg">
            <svg [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}"
                 class="h-8 w-8 block m-auto object-cover p-0.5"
                 viewBox="0 0 60 60">
              <path [ngClass]="svgColorClasses()" class="fill-none"
                    d="m52.61,24.57c0,10.71-5.63,18.77-22.53,33.79M7.55,24.57c0,10.71,5.63,18.77,22.53,33.79M7.55,24.57C7.55,12.12,17.64,2.04,30.08,2.04s22.53,10.09,22.53,22.53m-13.14,0c0,5.18-4.2,9.39-9.39,9.39s-9.39-4.2-9.39-9.39,4.2-9.39,9.39-9.39,9.39,4.2,9.39,9.39Z"
                    style="stroke-width: 2.5px"/>
            </svg>
            <span [ngClass]="{'lg:hidden': !headerService.search()}"
                  class="text-xs mt-1">
              {{ 'cities' | translate }}</span>
            <span [class.lg:block]="!headerService.search()"
                  class="hidden pr-4">{{ 'caliceoCenters' | translate }}</span>
          </button>
          <ul class="absolute grid max-h-[80vh] overflow-y-auto gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-40 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
            @if (headerService.type() !== 'minimal') {
              <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                   xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 381.95 150.4">
                <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
              </svg>
            }
            @for (center of centerService.centers(); track center.id) {
              <li class="text-gray-500 grow rounded select-none cursor-pointer font-normal
                hover:text-bleuCaliceo-300"
                  [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                  [attr.aria-selected]="selectionService.center()?.id === center.id">
                <a class="text-lg block truncate px-4 py-2 w-full"
                   [href]="center.url"
                   (click)="navigationService.goTo(center)"
                   (keyup.enter)="emulateClick($event)">
                  <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                  <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                </a>
              </li>
            }
          </ul>
        </div>
        <!--Go to products -->
        @if (siteService.center()) {
          <!--Go to shop button -->
          <a routerLink="/offers"
             [attr.aria-label]="'shop' | translate"
             class="mt-1 basis-0 md:basis-auto grow relative group flex flex-col items-center md:py-1 md:px-1 hover:bg-white hover:bg-opacity-20 md:rounded-lg"
             [ngClass]="textColorClasses()">
            <svg viewBox="0 0 60 60" class="h-8 w-8 block m-auto object-cover p-0.5"
                 [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                    stroke-miterlimit: 10;
                    stroke-width: 1.6px;
                  }
                </style>
              </defs>
              <g>
                <g>
                  <path class="cls-1" [ngClass]="svgColorClasses()"
                        d="M11.9,9v-2c0-2.9,2.3-5.2,5.2-5.2h25.9c2.9,0,5.2,2.3,5.2,5.2v2"/>
                  <path class="cls-1" [ngClass]="svgColorClasses()"
                        d="M48.1,28.6v24.4c0,2.9-2.3,5.2-5.2,5.2h-25.9c-2.9,0-5.2-2.3-5.2-5.2v-24.6"/>
                  <polyline class="cls-1" [ngClass]="svgColorClasses()" points="42.7 48.2 17.2 48.2 17.2 32.9"/>
                </g>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="26.3" y1="53.4" x2="33.7" y2="53.4"/>
              </g>
              <path class="cls-1" [ngClass]="svgColorClasses()"
                    d="M38.2,17.8v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5l-6.2-8.6H11.8l-6.2,8.6v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5"/>
              <path class="cls-1" [ngClass]="svgColorClasses()"
                    d="M21.8,20.2h0c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2h0"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="5.5" y1="17.8" x2="54.5" y2="17.8"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="21.8" y1="17.8" x2="24" y2="9.2"/>
              <line class="cls-1" [ngClass]="svgColorClasses()" x1="38.2" y1="17.8" x2="36" y2="9.2"/>
            </svg>
            <span class="text-xs"
                  [ngClass]="{'lg:text-base lg:pr-4': !headerService.search()}">
              {{ 'shop' | translate }}
            </span>
          </a>
          <!--Gift button -->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              class="group mx-auto flex flex-col items-center md:py-1 md:px-3 hover:bg-white hover:bg-opacity-20 md:rounded-lg"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-9 w-9 block m-auto object-cover"
                   [ngClass]="{'lg:h-12 lg:w-12': !headerService.search()}">
                <defs>
                  <style>.cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.76px;
                  }</style>
                </defs>
                <path class="cls-1 fill-none stroke-2 p-0.5"
                      [ngClass]="svgColorClasses()"
                      d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
              </svg>
              <span class="text-xs"
                    [ngClass]="{'lg:hidden': !headerService.search()}">
                {{ 'gift' | translate }}</span>
              <span class="hidden pr-4"
                    [class.lg:block]="!headerService.search()">{{ 'giftVouchers' | translate }}</span>
            </button>
            <ul class="absolute md:min-w-max w-[90vw] md:w-[unset] left-4 bottom-12
             md:-left-8 md:top-16 md:right-auto md:bottom-auto
            z-10 rounded-md bg-white py-4 text-base text-gray-500 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
            grid grid-cols-1">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              <li class="grow hover:text-bleuCaliceo-300 px-8 py-2 rounded select-none cursor-pointer font-normal">
                <a routerLink="/offers" [queryParams]="{gift: true}" class="flex gap-4 items-center">
                  <div class="relative h-8 w-8">
                    <img alt="" fill="" class="object-fill" ngSrc="assets/icons/gray/gift.svg">
                  </div>
                  <span class="font-semibold text-end">{{ 'buyGift' | translate }}</span>
                </a>
              </li>
              @if (showClaimGift) {
                <li class="border-y border-gray-200 grow hover:text-bleuCaliceo-300 px-8 py-2 rounded select-none
                cursor-pointer font-normal">
                  <button aria-labelledby="claimGift"
                          (click)="openClaimGiftComponent()"
                          class="flex gap-4 items-center">
                    <div class="relative h-8 w-8">
                      <img alt="" fill="" class="object-fill" ngSrc="assets/icons/gray/use_gift.svg">
                    </div>
                    <span id="claimGift" class="font-semibold text-end">{{ 'claimGift' | translate }}</span>
                  </button>
                </li>
              }
              <li class="grow hover:text-bleuCaliceo-300 px-8 py-2 rounded select-none cursor-pointer font-normal">
                <a routerLink="/vouchers" class="flex gap-4 items-center">
                  <div class="relative h-8 w-8">
                    <img alt="" fill="" class="object-fill" ngSrc="assets/icons/gray/cheque.svg">
                  </div>
                  <span class="font-semibold text-end">{{ 'buyVoucher' | translate }}</span>
                </a>
              </li>
            </ul>
          </div>
        } @else {
          <!--Display shops -->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              aria-labelledby="shop"
              class="group mx-auto flex flex-col lg:flex-row items-center lg:gap-3 md:py-1 md:px-3 md:rounded-lg hover:bg-white hover:bg-opacity-20"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-8 w-8 block m-auto object-cover p-0.5"
                   [ngClass]="{'lg:h-10 lg:w-10': !headerService.search()}">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                      stroke-miterlimit: 10;
                      stroke-width: 1.6px;
                    }
                  </style>
                </defs>
                <g>
                  <g>
                    <path class="cls-1" [ngClass]="svgColorClasses()"
                          d="M11.9,9v-2c0-2.9,2.3-5.2,5.2-5.2h25.9c2.9,0,5.2,2.3,5.2,5.2v2"/>
                    <path class="cls-1" [ngClass]="svgColorClasses()"
                          d="M48.1,28.6v24.4c0,2.9-2.3,5.2-5.2,5.2h-25.9c-2.9,0-5.2-2.3-5.2-5.2v-24.6"/>
                    <polyline class="cls-1" [ngClass]="svgColorClasses()" points="42.7 48.2 17.2 48.2 17.2 32.9"/>
                  </g>
                  <line class="cls-1" [ngClass]="svgColorClasses()" x1="26.3" y1="53.4" x2="33.7" y2="53.4"/>
                </g>
                <path class="cls-1" [ngClass]="svgColorClasses()"
                      d="M38.2,17.8v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5l-6.2-8.6H11.8l-6.2,8.6v2.5c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2v-2.5"/>
                <path class="cls-1" [ngClass]="svgColorClasses()"
                      d="M21.8,20.2h0c0,4.5,3.6,8.2,8.2,8.2h0c4.5,0,8.2-3.6,8.2-8.2h0"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="5.5" y1="17.8" x2="54.5" y2="17.8"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="21.8" y1="17.8" x2="24" y2="9.2"/>
                <line class="cls-1" [ngClass]="svgColorClasses()" x1="38.2" y1="17.8" x2="36" y2="9.2"/>
              </svg>
              <span class="text-xs lg:text-base lg:pr-4">{{ 'shop' | translate }}</span>
            </button>
            <ul class="absolute grid max-h-[80vh] overflow-y-auto  gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-52 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              @for (center of centerService.centers(); track center.id) {
                <li class="text-gray-500 grow hover:text-bleuCaliceo-300 rounded select-none cursor-pointer font-normal"
                    [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                    [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <button class="text-lg block truncate px-4 py-2 w-full"
                          [disabled]="!center.url"
                          (click)="navigationService.goTo(center, '/offers')"
                          (keyup.enter)="emulateClick($event)">
                    <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                    <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                  </button>
                </li>
              }
            </ul>
          </div>
          <!--Gift button which display shops-->
          <div appHover appAutoClose="always" class="basis-0 md:basis-auto grow md:relative">
            <button
              aria-labelledby="giftVouchers"
              class="group mx-auto flex flex-col lg:flex-row items-center lg:gap-3 md:py-1 md:px-3 rounded-lg hover:bg-white hover:bg-opacity-20"
              [ngClass]="textColorClasses()">
              <svg viewBox="0 0 60 60" class="h-9 w-9 lg:h-12 lg:w-12 block m-auto object-cover">
                <defs>
                  <style>.cls-1 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.76px;
                  }</style>
                </defs>
                <path class="cls-1 fill-none stroke-2"
                      [ngClass]="svgColorClasses()"
                      d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
              </svg>
              <span class="lg:hidden text-xs">{{ 'gift' | translate }}</span>
              <span class="hidden lg:block pr-4">{{ 'giftVouchers' | translate }}</span>
            </button>
            <ul class="absolute grid max-h-[80vh] overflow-y-auto  gap-2 z-10 rounded-md bg-white py-4 px-5 ring-1
            text-base shadow-lg ring-black ring-opacity-5 bottom-14 left-4 right-4 m-auto focus:outline-none
            md:min-w-max md:-left-64 md:grid-cols-2 md:max-w-fit md:top-16 md:bottom-auto md:px-7 sm:text-sm">
              @if (headerService.type() !== 'minimal') {
                <svg class="fill-white h-3 mx-auto hidden lg:block absolute right-0 left-0 -top-3"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                     viewBox="0 0 381.95 150.4">
                  <path class="fill-white" d="M0,150.4C51.71,98.69,102.14,48.26,121.71,28.69C126.33,24.08,151.32,0,190.98,0
	c25.07,0,50.14,9.56,69.27,28.69c20.7,20.7,71.87,71.87,121.71,121.71"/>
                </svg>
              }
              @for (center of centerService.centers(); track center.id) {
                <li
                  class="text-gray-500 grow hover:text-bleuCaliceo-300 rounded select-none cursor-pointer font-normal"
                  [ngClass]="{
                  'font-semibold': selectionService.center()?.id === center.id,
                  '!bg-gray-100 hover:!bg-gray-100 cursor-default': !center.url
                  }"
                  [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <button class="text-lg block truncate px-4 py-2 w-full"
                          [disabled]="!center.url"
                          (click)="navigationService.goTo(center, '/offers?gift=true')"
                          (keyup.enter)="emulateClick($event)">
                    <p class="w-full text-sm text-left leading-6 font-semibold">{{ center.name }}</p>
                    <p class="w-full text-xs text-left leading-5 truncate">{{ center.city }}</p>
                  </button>
                </li>
              }
            </ul>
          </div>
        }
        <!--Checkout block-->
        @if (siteService.center()) {
          <div class="self-stretch !border-l my-4 lg:mx-2 md:mx-2 hidden md:block"
               [ngClass]="!giftMode()?(headerService.type() === 'minimal' ? 'border-bleuCaliceo-300' : 'border-white')
               :(headerService.type() === 'minimal' ? 'border-bleuCaliceo-300' : 'border-white')">
          </div>
          <a routerLink="/checkout"
             [attr.aria-label]="'goToCart' | translate"
             class="basis-0 md:basis-auto grow relative group flex items-center justify-center gap-3 md:py-2 md:px-2 hover:bg-white hover:bg-opacity-20"
             [ngClass]="textColorClasses()">
            <div class="flex flex-col items-center">
              <svg viewBox="0 0 24 24" class="w-9 h-9 block m-auto object-cover"
                   [ngClass]="{'lg:w-10 lg:h-10': !headerService.search()}">
                <path class="fill-none"
                      stroke-width="0.75"
                      [ngClass]="svgColorClasses()"
                      d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
              </svg>
              <span class="text-xs"
                    [ngClass]="{'lg:hidden': !headerService.search()}">{{ 'checkout' | translate }}</span>
            </div>
            @if (cartService.numberOfItems(); as cartLength) {
              <span
                class="absolute top-0 bottom-0 left-0 right-0 mt-3 md:mt-5  m-auto font-semibold text-center"
                [ngClass]="{'lg:static lg:mt-2': !headerService.search()}">{{ cartLength }}</span>
            }
          </a>
        }
        <!--Auth block-->
        @if (authService.user()?.authCompleted) {
          <div appAutoClose="outside" class="basis-0 md:basis-auto grow relative">
            <button [attr.aria-label]="'auth' | translate" class="group mx-auto
                  flex items-center gap-3 md:py-2 md:px-3">
              @if (authService.user()?.photoURL; as url) {
                <img class="w-12 h-12 rounded-full ring-2 ring-white shadow-md object-cover" width="48" height="48"
                     [ngSrc]="url" alt="Bordered avatar">
              } @else {
                <div
                  class="font-bold text-gray-500 rounded-full bg-white flex items-center
                  justify-center text-xl h-12 w-12 ring-2 ring-bleuCaliceo-300 shadow-md">
                  {{ authService.user()?.firstName?.substring(0, 1) ?? '' }}
                </div>
              }
            </button>
            <ul class="absolute min-w-max right-4 bottom-16 md:-right-20 md:top-16">
              <app-profile-card/>
            </ul>
          </div>
        } @else {
          <button [attr.aria-label]="'auth' | translate"
                  class="basis-0 md:basis-auto grow relative group mx-auto flex flex-col gap-2 items-center md:py-2 md:px-3
                  hover:bg-white hover:bg-opacity-20 md:rounded-lg
                  lg:hover:bg-transparent"
                  [disabled]="authService.initAuthProgress()"
                  (click)="drawerService.open({component: SignInComponent, inputs: { inDrawer: true }, style: 'transparent'})"
                  [ngClass]="textColorClasses()">
            <svg viewBox="0 0 40 40" class="h-7 w-7 block m-auto object-cover lg:hidden">
              <path
                class="fill-none stroke-2"
                [ngClass]="svgColorClasses()"
                d="M3.75,38.75c0-10,2.5-17.5,12.84-17.5h6.82c10.34,0,12.84,7.5,12.84,17.5 M30,11.25c0,5.52-4.48,10-10,10s-10-4.48-10-10 s4.48-10,10-10S30,5.73,30,11.25z"/>
            </svg>
            <span class="text-xs lg:hidden">{{ 'auth' | translate }}</span>
            <span class="hidden lg:block" appHoverGradient>
                @if (authService.initAuthProgress()) {
                  <svg class="animate-spin h-6 w-6 mx-8 text-white" xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                } @else {
                  {{ 'auth' | translate }}
                }
            </span>
          </button>
        }
      </div>
    </nav>
    <!--Reservation Card-->
    @if (headerService.type() === 'full') {
      <div class="layout pt-5">
        <!--Card-->
        <div
          class="relative block w-full md:max-w-lg rounded-lg bg-white px-8 lg:px-14 py-8 lg:py-14
            shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
          <app-lang-selector class="absolute top-4 right-4 z-20"/>
          @if (siteService.center(); as center) {
            <!--Subtitle-->
            <p class="text-gray-500 font-bold text-xl mb-6">{{ 'header.welcome' | translate }}</p>
            <!--Center title -->
            <h1 class="font-bold text-3xl md:text-4xl leading-tight text-bleuCaliceo-300 uppercase">
              {{ center.name }}
            </h1>
          } @else {
            <!--Subtitle-->
            <p class="text-gray-500 font-bold text-xl mb-6">{{ 'header.subtitle' | translate }}</p>
            <!--Center title -->
            <h1 class="font-bold text-3xl md:text-4xl leading-tight text-bleuCaliceo-300 uppercase">
              {{ 'header.title' | translate }}
            </h1>
          }
          <!-- Description -->
          <p [innerHtml]="'header.description' | translate" class="my-4 text-gray-500 font-semibold"></p>
          <!-- Center Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="centerChoice"
                    class="bg-gray-100 py-5 px-4 pr-10 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full rounded-md text-left text-gray-900 focus:outline-none">
              <div class="flex items-center">
                <div class="w-12">
                  <img class="h-9 w-9 flex-shrink-0 m-auto"
                       ngSrc="assets/icons/gray/location.svg" width="60" height="60"
                       alt="{{'caliceoLocation' | translate}}">
                </div>
                <div
                  class="truncate text-gray-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="centerChoice"
                     class="font-semibold truncate text-xs">{{ 'caliceoCenters' | translate }}</p>
                  {{ selectionService.center()?.name }}
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                <img ngSrc="assets/icons/gray/down.svg" width="60" height="60" alt="" class="h-4 w-4">
              </div>
            </button>
            <ul
              class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
              shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              @for (center of centerService.centers(); track center.id) {
                <li [attr.aria-selected]="selectionService.center()?.id === center.id">
                  <div class="flex items-center py-4 px-3 md:px-5 cursor-pointer text-gray-900 hover:bg-gray-100
                    relative select-none" (click)="selectCenter(center)" role="button"
                       tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)">
                    <span [ngClass]="selectionService.center()?.id === center.id ? 'font-semibold' : 'font-normal'"
                          class="text-lg text-gray-500 ml-3 block truncate">
                      {{ center.name }} - {{ center.city }}
                    </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!-- Universe Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="universeChoice"
                    class="bg-gray-100 py-4 px-4 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full rounded-md text-left text-gray-900 focus:outline-none">
              <div class="flex items-center">
                <div class="w-12">
                  <img [ngSrc]="'assets/icons/gray/'+ (selectionService.universe()?.icon ?? 'spa.svg')" alt=""
                       width="36"
                       height="36"
                       class="h-9 w-9 flex-shrink-0 m-auto">
                </div>
                <div
                  class="truncate text-gray-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="universeChoice"
                     class="font-semibold truncate text-xs">{{ 'caliceoUniverseChoice' | translate }}</p>
                  @if (selectionService.universe(); as universe) {
                    {{ 'universe/' + universe.id + '/name' | translate }}
                  } @else {
                    {{ 'seeAll' | translate }}
                  }
                </div>
              </div>
              <div class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                <img ngSrc="assets/icons/gray/down.svg" width="60" height="60" alt="{{'caliceoUniverse' | translate}}"
                     class="h-4 w-4">
              </div>
            </button>
            <ul class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
              shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                  [attr.aria-selected]="selectionService.universe() === null">
                <div class="flex items-center" (click)="selectUniverse(null)" tabindex="0" role="button"
                     (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-gray-500 ml-3 block truncate"
                          [ngClass]="selectionService.universe() === null ? 'font-semibold' : 'font-normal'">
                      {{ 'seeAll' | translate }}
                    </span>
                </div>
              </li>
              @for (universe of universeService.universes(); track universe.id) {
                <li [attr.aria-selected]="selectionService.universe()?.id === universe.id">
                  <div class="flex items-center text-gray-900 hover:bg-gray-100 relative select-none py-4 px-5
                      md:px-7 cursor-pointer" (click)="selectUniverse(universe)" role="button"
                       tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)">
                    <img class="h-7 w-7 flex-shrink-0"
                         [ngSrc]="'assets/icons/gray/'+universe.icon" width="28" height="28"
                         alt="universe icon">
                    <span class="text-lg text-gray-500 ml-3 block truncate"
                          [ngClass]="selectionService.universe()?.id === universe.id ? 'font-semibold' : 'font-normal'">
                         {{ 'universe/' + universe.id + '/name' | translate }}
                      </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!-- Product Choice -->
          <div appAutoClose="always" class="relative mt-2">
            <button type="button"
                    aria-labelledby="productChoice"
                    class="bg-gray-100 py-4 px-4 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full
                      rounded-md text-left text-gray-900 focus:outline-none">
              <span class="flex items-center">
                <div class="w-12">
                <img ngSrc="assets/icons/gray/cards.svg" alt="{{'caliceoProduct' | translate}}" width="50" height="50"
                     class="h-12 w-12 flex-shrink-0 text-center">
                  </div>
                <span
                  class="truncate text-gray-500 font-semibold text-lg ml-4 md:ml-6">
                  <p id="productChoice" class="font-semibold truncate text-xs">{{ 'yourPass' | translate }}</p>
                  @if (selectionService.product(); as product) {
                    {{ 'product/' + product.id + '/name' | translate }}
                  } @else {
                    {{ 'seeAll' | translate }}
                  }
                </span>
              </span>
              <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
                 <img ngSrc="assets/icons/gray/down.svg" width="100" height="100"
                      alt="{{'caliceoProduct' | translate}}" class="h-4 w-4">
                </span>
            </button>
            <ul class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base
                shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                  [attr.aria-selected]="selectionService.product() === null">
                <div class="flex items-center" (click)="selectProduct(null)" tabindex="0" role="button"
                     (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-gray-500 ml-3 block truncate"
                          [ngClass]="selectionService.product() === null ? 'font-semibold' : 'font-normal'">
                      {{ 'seeAll' | translate }}
                    </span>
                </div>
              </li>
              @for (product of productService.quickPurchases(); track product.id) {
                <li class="text-gray-900 hover:bg-gray-100 relative select-none py-4 px-3 md:px-5 cursor-pointer"
                    [attr.aria-selected]="selectionService.product()?.id === product.id">
                  <div class="flex items-center" (click)="selectProduct(product)" tabindex="0" role="button"
                       (keyup.enter)="emulateClick($event)">
                    <span class="text-lg text-gray-500 ml-3 block truncate"
                          [ngClass]="selectionService.product()?.id === product.id ? 'font-semibold' : 'font-normal'">
                      {{ 'product/' + product.id + '/name' | translate }}
                    </span>
                  </div>
                </li>
              }
            </ul>
          </div>
          <!--Big Button-->
          <button appHoverGradient class="big-btn" (click)="quickPurchase()"
                  [disabled]="!selectionService.center()?.url">
            {{ 'continue' | translate }}
          </button>
        </div>
      </div>
    } @else if (headerService.type() === 'plain') {
      <div class="min-h-80"></div>
    }
  </div>
</header>

<ng-template #logoWhite>
  <svg class="h-18 lg:h-16 lg:w-auto" height="76" viewBox="0 0 266.9 67.7" width="300"
       xmlns="http://www.w3.org/2000/svg">
    <style type="text/css">
      .st1 {
        fill: #FFFFFF;
      }
    </style>
    <g>
      <path d="M44.1,20.7
		c-0.5,0.9-1.7,1.2-2.6,0.7c-0.9-0.5-1.2-1.7-0.7-2.6c0.5-0.9,1.7-1.2,2.6-0.7C44.3,18.6,44.6,19.8,44.1,20.7"
            style="fill:#FFFFFF;"/>
      <path d="M48,24.8
		c-0.8,0.7-2,0.6-2.7-0.2c-0.7-0.8-0.6-2,0.2-2.7c0.8-0.7,2-0.6,2.7,0.2C48.9,22.9,48.8,24.1,48,24.8"
            style="fill:#FFFFFF;"/>
      <path d="M50.3,30
		c-1,0.4-2.1-0.2-2.4-1.1c-0.4-1,0.2-2.1,1.1-2.4c1-0.4,2.1,0.2,2.4,1.1C51.8,28.6,51.3,29.7,50.3,30"
            style="fill:#FFFFFF;"/>
      <path d="M50.6,35.7
		c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9S51.7,35.7,50.6,35.7" style="fill:#FFFFFF;"/>
      <path d="M49,41.2
		c-1-0.4-1.5-1.5-1.1-2.4c0.4-1,1.5-1.5,2.4-1.1c1,0.4,1.5,1.5,1.1,2.4C51.1,41,50,41.5,49,41.2" style="fill:#FFFFFF;"/>
      <path d="M45.6,45.7
		c-0.8-0.7-0.9-1.9-0.2-2.7c0.7-0.8,1.9-0.9,2.7-0.2c0.8,0.7,0.9,1.9,0.2,2.7C47.6,46.3,46.4,46.4,45.6,45.7"
            style="fill:#FFFFFF;"/>
      <path d="M40.8,48.9
		c-0.5-0.9-0.2-2.1,0.7-2.6c0.9-0.5,2.1-0.2,2.6,0.7c0.5,0.9,0.2,2.1-0.7,2.6C42.5,50.1,41.4,49.8,40.8,48.9"
            style="fill:#FFFFFF;"/>
      <path d="M35.3,50.2
		c-0.2-1,0.5-2,1.5-2.2c1-0.2,2,0.5,2.2,1.5c0.2,1-0.5,2-1.5,2.2C36.5,51.9,35.5,51.2,35.3,50.2" style="fill:#FFFFFF;"/>
      <path d="M29.6,49.5
		c0.2-1,1.2-1.7,2.2-1.5c1,0.2,1.7,1.2,1.5,2.2c-0.2,1-1.2,1.7-2.2,1.5C30.1,51.6,29.5,50.6,29.6,49.5"
            style="fill:#FFFFFF;"/>
      <path d="M24.5,47
		c0.5-0.9,1.7-1.2,2.6-0.7c0.9,0.5,1.2,1.7,0.7,2.6c-0.5,0.9-1.7,1.2-2.6,0.7C24.3,49.1,24,47.9,24.5,47"
            style="fill:#FFFFFF;"/>
      <path d="M20.6,42.8
		c0.8-0.7,2-0.6,2.7,0.2c0.7,0.8,0.6,2-0.2,2.7c-0.8,0.7-2,0.6-2.7-0.2C19.7,44.7,19.8,43.5,20.6,42.8"
            style="fill:#FFFFFF;"/>
      <path d="M18.4,37.6
		c1-0.4,2.1,0.2,2.4,1.1c0.4,1-0.2,2.1-1.1,2.4c-1,0.4-2.1-0.2-2.4-1.1C16.9,39.1,17.4,38,18.4,37.6"
            style="fill:#FFFFFF;"/>
      <path d="M18,31.9
		c1.1,0,1.9,0.9,1.9,1.9c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9C16.1,32.8,17,31.9,18,31.9"
            style="fill:#FFFFFF;"/>
      <path d="M19.7,26.5
		c1,0.4,1.5,1.5,1.1,2.4c-0.4,1-1.5,1.5-2.4,1.1c-1-0.4-1.5-1.5-1.1-2.4C17.6,26.6,18.7,26.1,19.7,26.5"
            style="fill:#FFFFFF;"/>
      <path d="M23.1,21.9
		c0.8,0.7,0.9,1.9,0.2,2.7c-0.7,0.8-1.9,0.9-2.7,0.2c-0.8-0.7-0.9-1.9-0.2-2.7C21.1,21.3,22.3,21.2,23.1,21.9"
            style="fill:#FFFFFF;"/>
      <path d="M27.8,18.8
		c0.5,0.9,0.2,2.1-0.7,2.6c-0.9,0.5-2.1,0.2-2.6-0.7c-0.5-0.9-0.2-2.1,0.7-2.6C26.1,17.6,27.3,17.9,27.8,18.8"
            style="fill:#FFFFFF;"/>
      <path d="M33.4,17.5
		c0.2,1-0.5,2-1.5,2.2c-1,0.2-2-0.5-2.2-1.5c-0.2-1,0.5-2,1.5-2.2C32.2,15.7,33.2,16.4,33.4,17.5"
            style="fill:#FFFFFF;"/>
      <path d="M39,18.1
		c-0.2,1-1.2,1.7-2.2,1.5c-1-0.2-1.7-1.2-1.5-2.2c0.2-1,1.2-1.7,2.2-1.5C38.5,16.1,39.2,17.1,39,18.1"
            style="fill:#FFFFFF;"/>
      <path d="M44,14.6
		c-0.5,1.3-1.9,2-3.2,1.5c-1.3-0.5-2-1.9-1.5-3.2c0.5-1.3,1.9-2,3.2-1.5C43.8,11.9,44.5,13.3,44,14.6"
            style="fill:#FFFFFF;"/>
      <path d="M50,19.1
		c-0.9,1.1-2.5,1.2-3.5,0.3c-1.1-0.9-1.2-2.5-0.3-3.5c0.9-1.1,2.5-1.2,3.5-0.3C50.7,16.4,50.9,18,50,19.1"
            style="fill:#FFFFFF;"/>
      <path d="M54.1,25.3
		c-1.2,0.7-2.7,0.3-3.4-0.9c-0.7-1.2-0.3-2.7,0.9-3.4c1.2-0.7,2.7-0.3,3.4,0.9C55.7,23.1,55.3,24.6,54.1,25.3"
            style="fill:#FFFFFF;"/>
      <path d="M55.8,32.6
		c-1.4,0.2-2.7-0.7-2.9-2c-0.2-1.4,0.7-2.7,2-2.9c1.4-0.2,2.7,0.7,2.9,2C58.1,31,57.2,32.3,55.8,32.6"
            style="fill:#FFFFFF;"/>
      <path d="M54.9,40
		c-1.4-0.2-2.3-1.5-2-2.9c0.2-1.4,1.5-2.3,2.9-2c1.4,0.2,2.3,1.5,2,2.9C57.6,39.3,56.3,40.2,54.9,40"
            style="fill:#FFFFFF;"/>
      <path d="M51.6,46.6
		c-1.2-0.7-1.6-2.2-0.9-3.4c0.7-1.2,2.2-1.6,3.4-0.9c1.2,0.7,1.6,2.2,0.9,3.4C54.3,46.9,52.8,47.3,51.6,46.6"
            style="fill:#FFFFFF;"/>
      <path d="M46.1,51.8
		c-0.9-1.1-0.8-2.6,0.3-3.5c1.1-0.9,2.6-0.8,3.5,0.3c0.9,1.1,0.8,2.6-0.3,3.5C48.6,53,47,52.8,46.1,51.8"
            style="fill:#FFFFFF;"/>
      <path d="M39.3,54.7
		c-0.5-1.3,0.2-2.7,1.5-3.2c1.3-0.5,2.7,0.2,3.2,1.5c0.5,1.3-0.2,2.7-1.5,3.2S39.8,56,39.3,54.7" style="fill:#FFFFFF;"/>
      <path d="M31.8,55.1
		c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5C33,57.7,31.8,56.5,31.8,55.1" style="fill:#FFFFFF;"/>
      <path d="M24.7,53
		c0.5-1.3,1.9-2,3.2-1.5c1.3,0.5,2,1.9,1.5,3.2c-0.5,1.3-1.9,2-3.2,1.5C24.9,55.7,24.2,54.3,24.7,53"
            style="fill:#FFFFFF;"/>
      <path d="M18.7,48.5
		c0.9-1.1,2.5-1.2,3.5-0.3c1.1,0.9,1.2,2.5,0.3,3.5c-0.9,1.1-2.5,1.2-3.5,0.3C17.9,51.2,17.8,49.6,18.7,48.5"
            style="fill:#FFFFFF;"/>
      <path d="M14.6,42.3
		c1.2-0.7,2.7-0.3,3.4,0.9c0.7,1.2,0.3,2.7-0.9,3.4c-1.2,0.7-2.7,0.3-3.4-0.9C13,44.5,13.4,43,14.6,42.3"
            style="fill:#FFFFFF;"/>
      <path d="M12.9,35
		c1.4-0.2,2.7,0.7,2.9,2c0.2,1.4-0.7,2.7-2,2.9c-1.4,0.2-2.7-0.7-2.9-2C10.6,36.6,11.5,35.3,12.9,35"
            style="fill:#FFFFFF;"/>
      <path d="M13.7,27.6
		c1.4,0.2,2.3,1.5,2,2.9c-0.2,1.4-1.5,2.3-2.9,2c-1.4-0.2-2.3-1.5-2-2.9C11.1,28.3,12.4,27.4,13.7,27.6"
            style="fill:#FFFFFF;"/>
      <path d="M17.1,21
		c1.2,0.7,1.6,2.2,0.9,3.4c-0.7,1.2-2.2,1.6-3.4,0.9c-1.2-0.7-1.6-2.2-0.9-3.4C14.4,20.7,15.9,20.3,17.1,21"
            style="fill:#FFFFFF;"/>
      <path d="M22.5,15.8
		c0.9,1.1,0.8,2.6-0.3,3.5c-1.1,0.9-2.6,0.8-3.5-0.3c-0.9-1.1-0.7-2.6,0.3-3.5C20.1,14.6,21.6,14.8,22.5,15.8"
            style="fill:#FFFFFF;"/>
      <path d="M29.4,12.9
		c0.5,1.3-0.2,2.7-1.5,3.2c-1.3,0.5-2.7-0.2-3.2-1.5c-0.5-1.3,0.2-2.7,1.5-3.2C27.5,10.9,28.9,11.6,29.4,12.9"
            style="fill:#FFFFFF;"/>
      <path d="M36.8,12.5
		c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5C35.7,9.9,36.8,11.1,36.8,12.5"
            style="fill:#FFFFFF;"/>
      <path d="M46.2,6
		c-0.3,0.9-1.3,1.3-2.2,1c-0.9-0.3-1.3-1.3-1-2.2c0.3-0.9,1.3-1.3,2.2-1C46.1,4.2,46.5,5.2,46.2,6"
            style="fill:#FFFFFF;"/>
      <path d="M55,11.8
		c-0.6,0.7-1.7,0.8-2.4,0.2c-0.7-0.6-0.8-1.7-0.2-2.4c0.6-0.7,1.7-0.8,2.4-0.2C55.5,10,55.6,11.1,55,11.8"
            style="fill:#FFFFFF;"/>
      <path d="M61.3,20.2
		c-0.8,0.5-1.8,0.2-2.3-0.6c-0.5-0.8-0.2-1.8,0.6-2.3c0.8-0.5,1.8-0.2,2.3,0.6C62.4,18.7,62.1,19.7,61.3,20.2"
            style="fill:#FFFFFF;"/>
      <path d="M64.4,30.3
		c-0.9,0.2-1.8-0.4-1.9-1.4c-0.2-0.9,0.5-1.8,1.4-1.9c0.9-0.2,1.8,0.4,2,1.4C65.9,29.2,65.3,30.1,64.4,30.3"
            style="fill:#FFFFFF;"/>
      <path d="M63.8,40.8
		c-0.9-0.2-1.5-1-1.4-1.9c0.2-0.9,1-1.5,1.9-1.4c0.9,0.2,1.5,1,1.4,1.9C65.6,40.3,64.7,40.9,63.8,40.8"
            style="fill:#FFFFFF;"/>
      <path d="M59.6,50.4
		c-0.8-0.5-1.1-1.5-0.6-2.3c0.5-0.8,1.5-1.1,2.3-0.6c0.8,0.5,1.1,1.5,0.6,2.3C61.5,50.6,60.4,50.9,59.6,50.4"
            style="fill:#FFFFFF;"/>
      <path d="M52.5,58.1
		c-0.6-0.7-0.5-1.8,0.2-2.4c0.7-0.6,1.8-0.5,2.4,0.2c0.6,0.7,0.5,1.8-0.2,2.4C54.1,58.9,53,58.8,52.5,58.1"
            style="fill:#FFFFFF;"/>
      <path d="M43.1,62.8
		c-0.3-0.9,0.1-1.8,1-2.2c0.9-0.3,1.8,0.1,2.2,1c0.3,0.9-0.1,1.8-1,2.2C44.4,64.1,43.4,63.7,43.1,62.8"
            style="fill:#FFFFFF;"/>
      <path d="M32.6,64.1
		c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7C33.4,65.7,32.6,65,32.6,64.1"
            style="fill:#FFFFFF;"/>
      <path d="M22.4,61.7
		c0.3-0.9,1.3-1.3,2.2-1c0.9,0.3,1.3,1.3,1,2.2c-0.3,0.9-1.3,1.3-2.2,1C22.5,63.5,22.1,62.5,22.4,61.7"
            style="fill:#FFFFFF;"/>
      <path d="M13.6,55.9
		c0.6-0.7,1.7-0.8,2.4-0.2c0.7,0.6,0.8,1.7,0.2,2.4c-0.6,0.7-1.7,0.8-2.4,0.2C13.1,57.7,13,56.6,13.6,55.9"
            style="fill:#FFFFFF;"/>
      <path d="M7.3,47.5
		c0.8-0.5,1.8-0.2,2.3,0.6c0.5,0.8,0.2,1.8-0.6,2.3c-0.8,0.5-1.8,0.2-2.3-0.6C6.2,49,6.5,48,7.3,47.5"
            style="fill:#FFFFFF;"/>
      <path d="M4.3,37.4
		c0.9-0.2,1.8,0.4,1.9,1.4c0.2,0.9-0.4,1.8-1.4,1.9c-0.9,0.2-1.8-0.4-1.9-1.4C2.8,38.5,3.4,37.6,4.3,37.4"
            style="fill:#FFFFFF;"/>
      <path d="M4.9,27
		c0.9,0.2,1.5,1,1.4,1.9c-0.2,0.9-1,1.5-1.9,1.4c-0.9-0.2-1.5-1-1.4-2C3.1,27.4,3.9,26.8,4.9,27" style="fill:#FFFFFF;"/>
      <path d="M9,17.3
		c0.8,0.5,1.1,1.5,0.6,2.3c-0.5,0.8-1.5,1.1-2.3,0.6c-0.8-0.5-1.1-1.5-0.6-2.3C7.2,17.1,8.2,16.8,9,17.3"
            style="fill:#FFFFFF;"/>
      <path d="M16.2,9.6
		c0.6,0.7,0.5,1.8-0.2,2.4c-0.7,0.6-1.8,0.5-2.4-0.2c-0.6-0.7-0.5-1.8,0.2-2.4S15.6,8.9,16.2,9.6"
            style="fill:#FFFFFF;"/>
      <path d="M25.6,4.9
		c0.3,0.9-0.1,1.8-1,2.2c-0.9,0.3-1.8-0.1-2.2-1c-0.3-0.9,0.1-1.8,1-2.2C24.3,3.6,25.2,4,25.6,4.9"
            style="fill:#FFFFFF;"/>
      <path d="M36,3.6
		c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7C35.2,2,36,2.7,36,3.6" style="fill:#FFFFFF;"/>
      <path d="M49,12.5
		c-0.6,1-1.9,1.3-2.9,0.8c-1-0.6-1.3-1.9-0.8-2.9c0.6-1,1.9-1.3,2.9-0.8C49.2,10.3,49.6,11.5,49,12.5"
            style="fill:#FFFFFF;"/>
      <path d="M55.4,18.8
		c-0.9,0.7-2.2,0.6-3-0.3c-0.7-0.9-0.6-2.2,0.3-3c0.9-0.7,2.2-0.6,3,0.3C56.4,16.8,56.3,18.1,55.4,18.8"
            style="fill:#FFFFFF;"/>
      <path d="M59.2,26.9
		c-1.1,0.4-2.3-0.2-2.7-1.3c-0.4-1.1,0.2-2.3,1.3-2.7c1.1-0.4,2.3,0.2,2.7,1.3C60.9,25.3,60.3,26.5,59.2,26.9"
            style="fill:#FFFFFF;"/>
      <path d="M60.1,35.9
		c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1C62.2,34.9,61.2,35.9,60.1,35.9"
            style="fill:#FFFFFF;"/>
      <path d="M57.8,44.5
		c-1.1-0.4-1.6-1.6-1.3-2.7c0.4-1.1,1.6-1.6,2.7-1.3c1.1,0.4,1.6,1.6,1.3,2.7C60.1,44.4,58.9,44.9,57.8,44.5"
            style="fill:#FFFFFF;"/>
      <path d="M52.7,51.9
		c-0.9-0.7-1-2.1-0.3-2.9c0.7-0.9,2.1-1,3-0.3c0.9,0.7,1,2.1,0.3,3C54.9,52.5,53.6,52.7,52.7,51.9"
            style="fill:#FFFFFF;"/>
      <path d="M45.4,57.1
		c-0.6-1-0.2-2.3,0.8-2.9c1-0.6,2.3-0.2,2.9,0.8c0.6,1,0.2,2.3-0.8,2.9C47.3,58.5,46,58.1,45.4,57.1"
            style="fill:#FFFFFF;"/>
      <path d="M36.8,59.5
		c-0.2-1.1,0.6-2.2,1.7-2.4c1.1-0.2,2.2,0.6,2.4,1.7c0.2,1.1-0.6,2.2-1.7,2.4C38.1,61.4,37,60.6,36.8,59.5"
            style="fill:#FFFFFF;"/>
      <path d="M27.8,58.8
		c0.2-1.1,1.3-1.9,2.4-1.7c1.1,0.2,1.9,1.3,1.7,2.4c-0.2,1.1-1.3,1.9-2.4,1.7C28.4,61,27.6,59.9,27.8,58.8"
            style="fill:#FFFFFF;"/>
      <path d="M19.7,55.1
		c0.6-1,1.9-1.3,2.9-0.8c1,0.6,1.3,1.9,0.8,2.9c-0.6,1-1.9,1.3-2.9,0.8C19.4,57.3,19.1,56.1,19.7,55.1"
            style="fill:#FFFFFF;"/>
      <path d="M13.3,48.8
		c0.9-0.7,2.2-0.6,3,0.3c0.7,0.9,0.6,2.2-0.3,3c-0.9,0.7-2.2,0.6-3-0.3C12.3,50.8,12.4,49.5,13.3,48.8"
            style="fill:#FFFFFF;"/>
      <path d="M9.4,40.7
		c1.1-0.4,2.3,0.2,2.7,1.3c0.4,1.1-0.2,2.3-1.3,2.7c-1.1,0.4-2.3-0.2-2.7-1.3C7.8,42.3,8.3,41.1,9.4,40.7"
            style="fill:#FFFFFF;"/>
      <path d="M8.6,31.7
		c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1C6.5,32.7,7.4,31.7,8.6,31.7"
            style="fill:#FFFFFF;"/>
      <path d="M10.8,23.1
		c1.1,0.4,1.6,1.6,1.3,2.7c-0.4,1.1-1.6,1.6-2.7,1.3c-1.1-0.4-1.6-1.6-1.3-2.7C8.6,23.2,9.8,22.7,10.8,23.1"
            style="fill:#FFFFFF;"/>
      <path d="M15.9,15.7
		c0.9,0.7,1,2.1,0.3,3c-0.7,0.9-2.1,1-2.9,0.3c-0.9-0.7-1-2.1-0.3-3C13.7,15.1,15,14.9,15.9,15.7"
            style="fill:#FFFFFF;"/>
      <path d="M23.2,10.5
		c0.6,1,0.2,2.3-0.8,2.9c-1,0.6-2.3,0.2-2.9-0.8c-0.6-1-0.2-2.3,0.8-2.9C21.4,9.1,22.7,9.5,23.2,10.5"
            style="fill:#FFFFFF;"/>
      <path d="M31.9,8.1
		c0.2,1.1-0.6,2.2-1.7,2.4C29,10.7,28,10,27.8,8.8c-0.2-1.1,0.6-2.2,1.7-2.4C30.6,6.2,31.7,7,31.9,8.1"
            style="fill:#FFFFFF;"/>
      <path d="M40.8,8.8
		c-0.2,1.1-1.3,1.9-2.4,1.7c-1.1-0.2-1.9-1.3-1.7-2.4c0.2-1.1,1.3-1.9,2.4-1.7C40.3,6.6,41,7.7,40.8,8.8"
            style="fill:#FFFFFF;"/>
      <path d="M51.9,5.5
		c-0.3,0.5-0.9,0.7-1.4,0.4c-0.5-0.3-0.7-0.9-0.4-1.4C50.3,4,51,3.8,51.5,4.1C52,4.4,52.2,5,51.9,5.5"
            style="fill:#FFFFFF;"/>
      <path d="M60.5,13.2
		c-0.4,0.4-1.1,0.3-1.5-0.1c-0.4-0.4-0.3-1.1,0.1-1.5c0.4-0.4,1.1-0.3,1.5,0.1C61,12.2,60.9,12.9,60.5,13.2"
            style="fill:#FFFFFF;"/>
      <path d="M66,23.4
		c-0.5,0.2-1.2-0.1-1.4-0.6c-0.2-0.5,0.1-1.2,0.6-1.4c0.5-0.2,1.2,0.1,1.4,0.6C66.8,22.6,66.5,23.2,66,23.4"
            style="fill:#FFFFFF;"/>
      <path d="M67.6,34.9
		c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S68.2,34.9,67.6,34.9" style="fill:#FFFFFF;"/>
      <path d="M65.2,46.2
		c-0.5-0.2-0.8-0.8-0.6-1.4c0.2-0.5,0.8-0.8,1.3-0.6c0.5,0.2,0.8,0.8,0.6,1.4C66.4,46.1,65.8,46.4,65.2,46.2"
            style="fill:#FFFFFF;"/>
      <path d="M59.2,56
		c-0.4-0.4-0.5-1-0.1-1.5s1-0.5,1.5-0.1c0.4,0.4,0.5,1,0.1,1.5C60.3,56.3,59.6,56.4,59.2,56" style="fill:#FFFFFF;"/>
      <path d="M50.1,63.2
		c-0.3-0.5-0.1-1.1,0.4-1.4c0.5-0.3,1.1-0.1,1.4,0.4c0.3,0.5,0.1,1.1-0.4,1.4C51,63.8,50.4,63.7,50.1,63.2"
            style="fill:#FFFFFF;"/>
      <path d="M39.1,66.8
		c-0.1-0.6,0.3-1.1,0.9-1.2c0.6-0.1,1.1,0.3,1.2,0.9c0.1,0.6-0.3,1.1-0.9,1.2C39.7,67.7,39.2,67.4,39.1,66.8"
            style="fill:#FFFFFF;"/>
      <path d="M27.5,66.4
		c0.1-0.6,0.6-1,1.2-0.9c0.6,0.1,1,0.6,0.9,1.2c-0.1,0.6-0.6,1-1.2,0.9C27.8,67.5,27.4,67,27.5,66.4"
            style="fill:#FFFFFF;"/>
      <path d="M16.8,62.1
		c0.3-0.5,0.9-0.7,1.4-0.4c0.5,0.3,0.7,0.9,0.4,1.4c-0.3,0.5-0.9,0.7-1.4,0.4C16.7,63.3,16.5,62.6,16.8,62.1"
            style="fill:#FFFFFF;"/>
      <path d="M8.2,54.4
		c0.4-0.4,1.1-0.3,1.5,0.1C10,55,10,55.7,9.5,56c-0.4,0.4-1.1,0.3-1.5-0.1C7.7,55.5,7.7,54.8,8.2,54.4"
            style="fill:#FFFFFF;"/>
      <path d="M2.7,44.2
		c0.5-0.2,1.2,0.1,1.4,0.6C4.3,45.4,4,46,3.4,46.2c-0.5,0.2-1.2-0.1-1.4-0.6C1.9,45,2.2,44.4,2.7,44.2"
            style="fill:#FFFFFF;"/>
      <path d="M1.1,32.8
		c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1S0,34.4,0,33.8C0,33.3,0.5,32.8,1.1,32.8" style="fill:#FFFFFF;"/>
      <path d="M3.4,21.5
		C4,21.7,4.2,22.3,4,22.8c-0.2,0.5-0.8,0.8-1.4,0.6c-0.5-0.2-0.8-0.8-0.6-1.4S2.9,21.3,3.4,21.5" style="fill:#FFFFFF;"/>
      <path d="M9.5,11.6
		c0.4,0.4,0.5,1,0.1,1.5c-0.4,0.4-1,0.5-1.5,0.1c-0.4-0.4-0.5-1-0.1-1.5C8.4,11.3,9.1,11.3,9.5,11.6"
            style="fill:#FFFFFF;"/>
      <path d="M18.6,4.5
		c0.3,0.5,0.1,1.1-0.4,1.4c-0.5,0.3-1.1,0.1-1.4-0.4c-0.3-0.5-0.1-1.1,0.4-1.4C17.7,3.8,18.3,4,18.6,4.5"
            style="fill:#FFFFFF;"/>
      <path d="M29.6,0.9
		c0.1,0.6-0.3,1.1-0.9,1.2c-0.6,0.1-1.1-0.3-1.2-0.9c-0.1-0.6,0.3-1.1,0.9-1.2C28.9-0.1,29.5,0.3,29.6,0.9"
            style="fill:#FFFFFF;"/>
      <path d="M41.1,1.2
		c-0.1,0.6-0.6,1-1.2,0.9c-0.6-0.1-1-0.6-0.9-1.2c0.1-0.6,0.6-1,1.2-0.9C40.9,0.1,41.2,0.7,41.1,1.2"
            style="fill:#FFFFFF;"/>
      <path d="M25.7,34.7
		c0-0.3,0-0.5,0-0.8c0.1,0,0-0.1,0.1-0.2c0-0.2,0-0.5,0.1-0.7c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0.1-0.2
		c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.3,0.1-0.4
		c0.1-0.1,0.1-0.2,0.2-0.4c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.4,0.5-0.6
		c0.4-0.4,0.7-0.7,1.1-1c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.1,0.4-0.3,0.7-0.4c0.5-0.3,1-0.5,1.5-0.7c0.3-0.1,0.6-0.2,0.9-0.2
		c0.1,0,0.1,0,0.2-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0c0.5,0,1.1-0.1,1.6,0
		c0.4,0,0.7,0,1,0.1c0.5,0,0.9,0.2,1.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.2
		c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.4,0.3,0.7,0.7,0.9,1.2c0.1,0.3,0.2,0.5,0.2,0.8c0,0.2,0,0.3,0,0.5
		c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.2
		C40.7,30,40.5,30,40.4,30c-0.3,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0.1-0.2
		c0-0.1,0.1-0.2,0.1-0.2c0-0.2,0-0.4,0.1-0.5c0-0.4,0-0.7,0-1c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.1-0.1-0.2-0.2-0.3
		c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.4-1-0.6-1.7-0.7c-0.6-0.1-1.2,0-1.8,0c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1
		c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0.1
		c-0.3,0.1-0.5,0.3-0.8,0.5c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1,0.3-0.3,0.5-0.4,0.9
		c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0-0.1,0.2-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
		c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.7-0.1,1.5-0.2,2.2
		c0,0.3,0,0.7,0,1.1c0,0.3,0,0.7,0.1,1c0,0.1,0.1,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.2
		c0,0.3,0.2,0.6,0.2,0.9c0.1,0.2,0.2,0.5,0.4,0.8c0,0.1,0.1,0.2,0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.2,0.4,0.4,0.5,0.6
		c0.2,0.2,0.4,0.4,0.7,0.5c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.2,0,0.2,0.1
		c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0.1,0.3,0,0.4,0c0.2,0,0.3,0,0.4,0
		c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c0.4,0,0.7-0.1,1.1-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
		c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.3-0.2,0.5-0.4
		c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.1-0.2,0.3-0.2c0,0,0.1,0.1,0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.2
		c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.5-1,0.8c-0.3,0.2-0.7,0.5-1.1,0.7c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.3,0.1
		c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.4,0.1c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0.1-0.3,0-0.4,0.1
		c-0.6,0.1-1.3,0.1-1.9,0.1c-0.3,0-0.6,0-0.9-0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-0.5-0.1-0.7-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1C30.1,42,30,42,29.9,42c-0.4-0.2-0.8-0.4-1.1-0.6
		c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.2-0.4-0.3-0.5-0.4c-0.1-0.1-0.1-0.1-0.1-0.2c-0.2-0.2-0.4-0.4-0.6-0.7c-0.1-0.2-0.2-0.3-0.3-0.5
		c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.4-0.3-0.7-0.4-1.2c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.7
		c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.4C25.7,35,25.7,34.8,25.7,34.7" style="fill:#FFFFFF;"/>
      <path d="M165.8,39.4
		c0.1,0.2-0.2,0.5-0.3,0.6c-0.3,0.3-0.8,0.6-1.2,0.8c-0.2,0.1-0.4,0.2-0.7,0.4c-0.3,0.1-0.4,0.2-0.7,0.3c-0.4,0.2-1,0.4-1.5,0.6
		c-0.2,0.1-0.5,0.2-0.8,0.3c-0.5,0.2-1,0.3-1.6,0.4c-0.3,0-0.5,0.2-0.9,0.2c-0.6,0.1-1.2,0.1-1.9,0.2c-0.7,0-1.4,0-1.9-0.1
		c-0.2-0.1-0.5-0.1-0.7-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.1-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.6-0.2-1.2-0.2-1.8
		c0-2,0.2-4.1,0.3-6.2c0.3-6.9,0.7-13.8,1-20.8c0-0.7,0.1-1.4,0.1-2c0-0.3,0-0.7,0-1c0-0.2,0-0.3-0.1-0.4c0-0.2,0-0.3,0-0.5
		c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.3-0.1-0.5-0.3-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3,0-0.5-0.2-0.8-0.2
		c-0.3,0-0.6-0.1-0.9,0c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0.2-0.8,0.1c-0.1-0.4,0.4-0.5,0.6-0.7c0.3-0.1,0.6-0.3,1-0.4
		c0.3-0.1,0.7-0.2,1-0.3c0.4-0.1,0.7-0.2,1.1-0.3c0.7-0.2,1.5-0.3,2.3-0.4c0.8-0.1,1.6-0.2,2.5-0.2c0,0,0,0,0,0
		c0.5,0.1,1.1,0,1.5,0.1c0.2,0,0.3,0,0.4,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.3,0.6,0.5,0.8,0.9c0.1,0.3,0.2,0.5,0.2,0.8
		c0.2,1.3,0.1,2.9,0,4.5c-0.4,8.6-0.9,17.2-1.3,25.8c0,0.7-0.1,1.3-0.1,1.9c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3
		c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.3,0.5c0.1,0.2,0.3,0.3,0.6,0.4c0.2,0,0.4,0.1,0.7,0.1c0.3,0,0.5,0,0.7-0.1
		c0.3,0,0.5,0,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1c0.5-0.1,0.9-0.2,1.3-0.4c0.1,0,0.3-0.1,0.4-0.1C165.2,39.6,165.4,39.4,165.8,39.4"
            style="fill:#FFFFFF;"/>
      <path d="M175.2,5.1
		c0.4,0,0.8,0.1,1.2,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.2,0.2,0.4,0.4,0.5c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0,0.5,0.1,0.6c0,0.5,0,1-0.1,1.5c-0.1,0.3-0.2,0.6-0.3,0.9
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.3-0.2,0.5-0.4,0.6c-0.2,0.4-0.7,0.6-1,0.9c-0.3,0.1-0.5,0.2-0.8,0.4
		c-0.3,0.1-0.6,0.1-0.9,0.2c-0.6,0-1.3,0.1-2,0c-0.3-0.1-0.6-0.1-0.8-0.2c-0.2-0.1-0.3-0.2-0.6-0.3c-0.4-0.3-0.8-0.7-1-1.1
		c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.7-0.2-1.6,0-2.5c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.3-0.6,0.4-0.8
		c0.2-0.2,0.4-0.5,0.7-0.8c0.2-0.1,0.4-0.3,0.6-0.5c0.3-0.1,0.5-0.2,0.7-0.4c0.3,0,0.4-0.1,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1
		C174.5,5.2,174.8,5.1,175.2,5.1" style="fill:#FFFFFF;"/>
      <path d="M86.4,25.7
		c0-0.5,0.1-1.1,0.1-1.6c0.1-0.1,0.1-0.3,0.1-0.4c0.1-0.5,0.1-1.1,0.1-1.6c0.1-0.2,0.1-0.6,0.2-0.9c0.1-0.3,0.1-0.7,0.2-1
		c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.2-0.4c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.1-0.3,0.1-0.4c0-0.1,0.1-0.3,0.2-0.4
		c0.1-0.3,0.3-0.5,0.3-0.8c0.2-0.2,0.2-0.5,0.4-0.8c0.1-0.3,0.3-0.5,0.4-0.8c0.3-0.5,0.5-1,0.9-1.5c0.3-0.5,0.6-0.9,0.9-1.4
		c0.4-0.4,0.7-0.8,1.1-1.3c0.8-0.8,1.5-1.6,2.5-2.2c0.4-0.4,0.9-0.7,1.4-1c0.5-0.3,0.9-0.6,1.5-0.9c1-0.6,2.1-1,3.3-1.4
		c0.6-0.1,1.2-0.4,1.9-0.5c0.2,0,0.3-0.1,0.5-0.1c0.3-0.1,0.7-0.1,1-0.2c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1
		c0.2,0,0.4,0,0.6,0c1.1-0.1,2.3-0.2,3.5,0c0.8,0,1.5,0.1,2.2,0.2c1.1,0.1,2,0.3,3,0.5c0.3,0.1,0.6,0.2,0.9,0.3
		c0.3,0,0.6,0.2,0.9,0.3c0.3,0.1,0.6,0.2,0.8,0.3c0.5,0.2,1,0.5,1.5,0.8c0.5,0.3,0.9,0.6,1.3,1c0.8,0.7,1.4,1.5,1.9,2.5
		c0.2,0.6,0.4,1,0.3,1.8c0,0.3-0.1,0.7-0.1,1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.2,0.4c-0.2,0.2-0.3,0.5-0.5,0.6
		c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.5,0.3-0.9,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.7,0-1.5-0.1-2.1-0.2c-0.6-0.2-1.2-0.4-1.7-0.6
		c0-0.1-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1c0.1-0.1,0.1-0.3,0.1-0.5c0-0.2,0.1-0.3,0.1-0.5c0.1-0.4,0.1-0.8,0.2-1.1
		c0.1-0.8,0-1.5-0.1-2.2c-0.2-0.6-0.4-1.3-0.7-1.7c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.2-0.4-0.4-0.7-0.6c-0.9-0.8-2.2-1.3-3.8-1.5
		c-1.2-0.1-2.7-0.1-3.8,0.1c-0.3,0-0.4,0.1-0.6,0.1c-0.4,0-0.7,0.1-1,0.2c-0.1,0-0.1,0-0.2,0c0,0-0.1,0.1-0.1,0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.6,0.3-1.1,0.6-1.7,1
		c-0.8,0.6-1.5,1.2-2,2c-0.2,0.2-0.4,0.5-0.6,0.8c-0.3,0.5-0.7,1.1-1,1.7c-0.3,0.6-0.6,1.2-0.8,1.8c-0.1,0.3-0.3,0.6-0.3,1
		c-0.2,0.1-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5c0,0.2,0,0.4-0.1,0.6
		c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.4-0.2,0.7-0.2,1.1c0,0.4-0.1,0.7-0.2,1.1c-0.2,1.5-0.3,3.2-0.3,4.8
		c0.1,0.7,0,1.6,0.1,2.3c0,0.8,0.1,1.5,0.2,2.2c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.4,0.1,0.5c0,0.2,0,0.4,0.1,0.5c0,0.2,0,0.4,0.1,0.5
		c0.1,0.7,0.4,1.2,0.5,1.9c0.3,0.5,0.5,1.2,0.8,1.7c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.2,0.3,0.5,0.5,0.7c0.3,0.5,0.8,0.9,1.2,1.4
		c0.4,0.4,0.9,0.8,1.4,1.1c0.3,0.2,0.5,0.3,0.8,0.5c0.3,0.2,0.6,0.3,0.9,0.4c0.2,0,0.3,0.2,0.5,0.2c0.1,0.1,0.4,0.1,0.5,0.2
		c0.2,0,0.3,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.1c0.4,0.1,0.8,0.1,1.2,0.2c0.3,0.1,0.6,0.1,0.9,0.1
		c0.3,0,0.7,0,1,0.1c0.3,0,0.6,0,1,0c0.3,0,0.7,0,1,0c0.8-0.1,1.6-0.3,2.4-0.4c0.5-0.1,1-0.3,1.5-0.4c0.2-0.1,0.5-0.1,0.7-0.2
		c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.4-0.2,0.9-0.4,1.3-0.6c0.8-0.4,1.6-0.9,2.4-1.4
		c0.4-0.3,0.7-0.5,1.1-0.8c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.1,0.3-0.3,0.6-0.4c0.1,0.1,0.2,0.2,0.1,0.4c-0.2,0.5-0.4,0.8-0.7,1.2
		c-0.2,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.3,0.3-0.5,0.5c-0.7,0.6-1.4,1.2-2.1,1.7c-0.7,0.5-1.6,1-2.4,1.4c-0.2,0.1-0.4,0.2-0.7,0.3
		c-0.2,0.1-0.4,0.2-0.6,0.3c-0.4,0.2-0.9,0.4-1.4,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.1-0.8,0.2c-0.5,0.1-1,0.3-1.6,0.3
		c-0.2,0.1-0.6,0.1-0.8,0.1c-1.3,0.2-2.8,0.2-4.2,0.2c-0.7,0-1.4,0-2-0.1c-0.3-0.1-0.7,0-1-0.1c-0.3-0.1-0.6-0.1-0.9-0.2
		c-0.6-0.1-1.1-0.2-1.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3,0-0.4-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
		c-0.8-0.4-1.7-0.8-2.4-1.3c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.8-0.6-1.1-0.9c-0.1-0.1-0.2-0.2-0.3-0.4c-0.4-0.5-0.9-0.9-1.2-1.5
		c-0.2-0.4-0.5-0.7-0.7-1.1c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.6c-0.3-0.9-0.7-1.6-0.9-2.6c-0.2-0.4-0.3-0.9-0.3-1.4
		c-0.1-0.5-0.2-1-0.2-1.5c-0.1-0.2,0-0.5,0-0.8c0-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0-0.8C86.5,26.3,86.5,25.8,86.4,25.7"
            style="fill:#FFFFFF;"/>
      <path d="M166.3,18.4
		c-0.1-0.4,0.3-0.5,0.6-0.7c0.3-0.1,0.6-0.3,0.9-0.4c0.6-0.2,1.3-0.5,2-0.6c0.3-0.1,0.7-0.2,1.1-0.2c0.4-0.1,0.8-0.1,1.1-0.2
		c0.8-0.1,1.6-0.2,2.5-0.2c0,0,0,0,0,0c0.5,0,1,0,1.5,0.1c0.5,0.1,0.9,0.3,1.3,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.4,0.3,0.5
		c0,0.2,0.1,0.3,0.1,0.4c0.1,1,0.1,2,0.1,3c-0.1,3.1-0.2,6.2-0.4,9.4c-0.1,1.5-0.1,3-0.1,4.6c0,0.5,0,1,0,1.5c0,1,0.1,2,0.1,2.9
		c0,0.2,0,0.5,0,0.7c0,0.2,0.1,0.5,0.1,0.7c0,0.5,0.1,0.9,0.1,1.4c-0.2,0.2-0.8,0.2-1.1,0.4c-0.9,0.1-1.7,0.3-2.7,0.4
		c-0.9,0.1-1.9,0.2-2.9,0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.2-0.6-0.3-0.7-0.6c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2
		c0-0.2,0-0.5-0.1-0.7c0-1.8,0.2-3.8,0.3-5.6c0.1-2.5,0.3-5,0.4-7.5c0.1-2.5,0.4-5,0.3-7.5c0-0.1-0.1-0.2-0.1-0.3
		c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.1-0.3-0.2-0.4-0.3c-0.5-0.2-1.3-0.3-2-0.2
		C167.3,18.3,166.8,18.5,166.3,18.4" style="fill:#FFFFFF;"/>
      <path d="M233,3.4h0.6
		c0.1,0.2,0.5,0,0.6,0.2c0.2,0.1,0.4,0.1,0.5,0.2c0.3,0.2,0.6,0.4,0.7,0.8c0.2,0.2,0.2,0.5,0.3,0.8c0,0.1,0,0.2,0,0.3
		c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.1-0.2,0.4-0.3,0.5c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.3-0.5,0.6-0.7,0.8c-0.5,0.5-1.2,1-1.8,1.4
		c-0.7,0.4-1.3,0.8-2.1,1.1c-0.7,0.4-1.5,0.7-2.2,1c-0.4,0.1-0.8,0.3-1.2,0.4c-0.4,0.1-0.8,0.3-1.3,0.3c-0.2,0.1-0.5,0-0.7,0.1
		c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5-0.1-0.6-0.2c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.5-0.2,0.8-0.3
		c0.5-0.3,0.9-0.5,1.4-0.8c0.4-0.3,0.9-0.6,1.2-0.9c0.4-0.3,0.7-0.7,1.1-1c0.3-0.4,0.6-0.8,1-1.2c0.2-0.5,0.5-0.9,0.8-1.3
		c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.2,0.2-0.5,0.4-0.7c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.1,0.5-0.2
		C232.3,3.6,232.7,3.6,233,3.4L233,3.4z" style="fill:#FFFFFF;"/>
      <path d="M206.8,37.3
		c0.2,0,0.2,0.3,0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.2-0.3,0.5-0.5,0.6c-0.1,0.2-0.2,0.2-0.3,0.4c-0.2,0.1-0.3,0.2-0.4,0.4
		c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2,0.1-0.3,0.1-0.4,0.2c-0.4,0.2-0.7,0.4-1.1,0.6
		c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.1-0.7,0.3-1.2,0.4c-0.1,0-0.1,0.1-0.2,0.1
		c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.6,0.1-0.9,0.1
		c-0.3,0-0.6,0.1-0.8,0.1c-0.3,0-0.7,0-1,0c-0.7,0-1.5,0-2.1-0.1c-0.3,0-0.5,0-0.8,0c-0.3-0.1-0.7-0.1-1-0.2c-0.4,0-0.8-0.2-1.2-0.2
		c-0.1-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.1,0-0.2,0c-0.4-0.2-0.7-0.3-1.1-0.4c-0.1,0-0.1-0.1-0.1-0.1c-0.2,0-0.2-0.1-0.3-0.1
		c-0.3-0.2-0.6-0.3-0.9-0.5c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.3-1-0.8-1.5-1.2c-0.4-0.5-0.9-1.1-1.2-1.7
		c0,0,0-0.1,0-0.1c-0.2-0.2-0.3-0.4-0.3-0.7c-0.2-0.2-0.3-0.6-0.4-0.8c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.1-0.1-0.1-0.1-0.2
		c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.2,0-0.3-0.1-0.4c0-0.2,0-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6
		c0-0.1,0-0.1-0.1-0.2c-0.1-0.3,0-0.6-0.1-0.9c0-1.1,0-2.3,0.1-3.5c0-0.5,0.2-0.9,0.2-1.4c0.1-0.2,0.1-0.4,0.2-0.7
		c0.1-0.2,0.1-0.5,0.2-0.7c0-0.3,0.2-0.5,0.2-0.7c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.3,0.3-0.6,0.4-1c0.2-0.3,0.3-0.7,0.5-0.9
		c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.4,0.6-0.6
		c0.2-0.2,0.4-0.4,0.7-0.6c0.4-0.4,0.9-0.7,1.4-1.1c0.1,0,0.1-0.1,0.3-0.1c0.2-0.2,0.5-0.3,0.8-0.4c0.1,0,0.1-0.1,0.1-0.1
		c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8-0.3c0.4-0.1,0.7-0.2,1.1-0.3c0.1,0,0.2,0,0.3,0c0.4-0.1,0.9-0.1,1.2-0.3
		c0.5,0,1.1-0.1,1.6-0.2c1.9-0.1,3.7,0,5.3,0.2c0.1,0,0.1,0,0.1,0.1c0.6,0,0.9,0.2,1.4,0.3c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
		c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0.1,0.3,0.1,0.4,0.2
		c0.2,0,0.2,0.1,0.4,0.1c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.3,0.7,0.4,0.9,0.7c0.4,0.2,0.6,0.6,0.9,0.9c0.1,0.1,0.1,0.3,0.2,0.3
		c0.1,0.2,0.2,0.4,0.2,0.6c0,0.1,0.1,0.1,0.1,0.1c0,0.2,0,0.3,0.1,0.4c0.1,0.5,0.1,1,0,1.5c-0.1,0.2-0.1,0.4-0.1,0.5
		c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0-0.4,0.1-0.6,0.2
		c-0.2,0-0.4,0-0.6,0.1c-0.7,0.1-1.4,0-1.9-0.2c-0.1,0-0.2,0-0.2,0c-0.4-0.2-0.9-0.4-1.2-0.7c0-0.1,0-0.3,0.1-0.4
		c0.2-0.4,0.1-0.8,0.2-1.3c0.1-0.5,0-1.1,0-1.5c-0.1-0.2,0-0.5-0.2-0.7c0-0.3-0.1-0.4-0.1-0.6c-0.1-0.2-0.3-0.5-0.3-0.8
		c-0.2-0.3-0.4-0.5-0.6-0.8c-0.3-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.7-0.3-1-0.4c-0.2,0-0.3,0-0.4-0.1c-0.1,0-0.2,0-0.3,0
		c-0.3-0.2-1-0.1-1.3-0.2c-0.2,0-0.3,0-0.5,0.1c-0.3,0-0.7,0-0.9,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0.1-0.4,0.2
		c-0.4,0.1-0.7,0.3-1,0.5c-0.4,0.3-0.8,0.7-1.2,1.1c-0.2,0.3-0.5,0.6-0.7,1c0,0-0.1,0-0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5
		c0,0.1,0,0.1,0,0.2c-0.2,0.2-0.3,0.5-0.4,0.9c0,0.1-0.1,0.1-0.1,0.1c0,0.1-0.1,0.3-0.1,0.3c0,0.3-0.2,0.4-0.2,0.7
		c-0.2,0.3-0.2,0.7-0.3,1.1c-0.1,0.2-0.1,0.6-0.2,0.8c-0.1,0.7-0.2,1.3-0.3,2c-0.3,2.2-0.3,4.6-0.1,6.6c0,0,0,0.1,0.1,0.1
		c0,0.4,0.1,0.7,0.1,1.1c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.1c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3
		c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.3,0.1,0.3c0,0.3,0.2,0.5,0.2,0.8c0.1,0.1,0.2,0.3,0.2,0.5c0.1,0.3,0.3,0.5,0.4,0.8
		c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.6,0.5,0.9,0.7c0,0.1,0.2,0.1,0.2,0.2
		c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.1,0.4,0.1
		c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0.8,0.2,1.7,0.1,2.6,0c0.2-0.1,0.5,0,0.7-0.1c0.5-0.2,1-0.2,1.5-0.3
		c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.6-0.2,0.8-0.3c0.1,0,0.1,0,0.2,0c0.4-0.2,0.8-0.4,1.3-0.5
		c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.2,0.5-0.3,0.8-0.4c0.2-0.2,0.4-0.2,0.6-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.2,0.6-0.4,0.8-0.6
		C206.6,37.5,206.6,37.4,206.8,37.3" style="fill:#FFFFFF;"/>
      <path d="M224.1,26.7
		c0.8,0,1.5,0.1,2.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.5-0.3,0.8-0.4
		c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.5,0.3-0.7c0-0.2,0.1-0.5,0.1-0.7c0.1-0.6,0.1-1.4,0-2c0-0.2-0.1-0.4-0.1-0.6
		c0-0.2,0-0.3,0-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.3-0.6-0.5-1
		c-0.1-0.2-0.3-0.3-0.3-0.5c-0.2-0.2-0.3-0.4-0.6-0.5c-0.2-0.2-0.4-0.3-0.7-0.5c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1,0-0.3,0-0.3-0.1
		c-0.5-0.1-1.1-0.2-1.7-0.2c-0.5,0.1-1.1,0.1-1.6,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0.1-0.5,0.2-0.8,0.3c-0.1,0.1-0.3,0.2-0.4,0.2
		c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.2-0.5,0.4-0.7,0.7c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.3-0.3,0.5
		c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.3-0.3,0.4-0.3,0.7c0,0.1-0.1,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.3-0.2,0.4-0.2,0.6
		c-0.2,0.2-0.1,0.5-0.3,0.8c-0.1,0.7-0.4,1.4-0.4,2.1c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.3-0.1,0.6,0,0.8
		C219.5,26.7,221.8,26.7,224.1,26.7 M217.1,27.7c-0.1,1-0.2,2.2-0.1,3.2c0.1,0.6,0,1.4,0.2,1.9c0,0.2,0,0.5,0.1,0.7
		c0,0.3,0.1,0.5,0.1,0.8c0.2,0.4,0.2,0.9,0.4,1.2c0,0.1,0,0.1,0,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.5,0.3,0.7
		c0.1,0.2,0.3,0.4,0.3,0.6c0.1,0.1,0.2,0.2,0.2,0.4c0.4,0.5,0.7,0.9,1.2,1.3c0.3,0.3,0.7,0.5,1,0.8c0.4,0.1,0.6,0.4,1,0.5
		c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0.1c0.2,0,0.4,0,0.5,0.1
		c1,0.1,2.1,0,3-0.1c0.4-0.1,0.9-0.2,1.4-0.3c0.1,0,0.1-0.1,0.2-0.1c0.4-0.1,0.7-0.2,1-0.3c0.1,0,0.1,0,0.2,0
		c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1-0.1,0.1-0.1c0.5-0.1,0.9-0.4,1.3-0.6c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.2,0.5-0.2,0.6-0.4
		c0.7-0.3,1.2-0.9,1.8-1.3c0.1,0.2,0.1,0.6-0.1,0.7c0,0.2-0.2,0.3-0.3,0.6c-0.2,0.1-0.3,0.3-0.4,0.5c-0.3,0.2-0.5,0.5-0.8,0.7
		c-0.3,0.2-0.6,0.4-0.8,0.6c-0.2,0.1-0.3,0.2-0.4,0.2c-0.2,0.2-0.4,0.2-0.6,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.2-0.6,0.2-0.8,0.4
		c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.3,0-0.4,0.1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c-0.4,0.2-0.9,0.2-1.2,0.3c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0.1-0.6,0.1c-1,0.1-2.1,0-3.2,0c-0.5-0.1-1.3-0.1-1.7-0.3
		c-0.4,0-0.8-0.2-1.2-0.2c-0.1,0-0.1-0.1-0.1-0.1c-0.3,0-0.5-0.2-0.8-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1-0.1-0.3-0.1-0.4-0.2
		c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.2-0.5-0.3-0.7-0.4c0,0,0-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.3-0.8-0.6-1.1-1
		c-0.2-0.2-0.4-0.3-0.5-0.5c-0.2-0.1-0.3-0.4-0.5-0.5c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.6
		c-0.1-0.5-0.5-0.9-0.6-1.4c-0.1-0.2-0.2-0.5-0.3-0.8c0-0.2,0-0.3-0.1-0.4c0-0.1,0-0.2,0-0.3c-0.1-0.2-0.1-0.5-0.2-0.6
		c0-0.2,0-0.4-0.1-0.5c0-0.2,0-0.5-0.1-0.6c0-0.3,0-0.7-0.1-1c0-1.3,0-2.6,0.2-3.9c0,0,0-0.1,0.1-0.1c0.1-1.1,0.4-2,0.7-3
		c0-0.1,0.1-0.1,0.1-0.1c0-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.1,0.1-0.1c0-0.2,0.2-0.3,0.2-0.5c0.1-0.1,0.1-0.1,0.1-0.2
		c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.4,0.3-0.6c0.1,0,0.1-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.1,0,0.1-0.1
		c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.1,0.3-0.4,0.5-0.5c0.3-0.5,0.7-0.7,1.1-1.1c0.3-0.2,0.4-0.4,0.7-0.6
		c0.3-0.2,0.5-0.4,0.7-0.6c0.1,0,0.1,0,0.2-0.1c0.3-0.2,0.7-0.4,1-0.6c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.1,0,0.1,0
		c0.1,0,0.1-0.1,0.3-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1c0.4-0.1,0.7-0.2,1-0.3
		c0.2,0,0.4,0,0.5-0.1c0.3,0,0.5-0.1,0.8-0.1c0.2-0.1,0.6-0.1,0.9-0.1c0.7-0.2,1.6-0.1,2.5-0.2c0.3,0,0.7,0,1.1,0
		c0.4,0,0.9,0,1.2,0.1c1,0,1.7,0.2,2.6,0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3,0c0.3,0.1,0.6,0.3,1,0.3c0.4,0.3,1,0.4,1.4,0.7
		c0.5,0.2,0.8,0.5,1.2,0.8c0.4,0.3,0.7,0.7,1,1c0.2,0.4,0.5,0.6,0.6,1.1c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.2,0.7
		c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0,0.2c0.3,1.1,0.3,2.8-0.2,3.8c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5
		c-0.2,0.2-0.4,0.4-0.6,0.5c-0.3,0.2-0.6,0.3-0.9,0.5c-0.4,0.1-0.7,0.2-1,0.3c-0.5,0-0.9,0.2-1.4,0.2c-1.1,0.1-2.3,0.1-3.5,0.1
		C224.1,27.7,220.5,27.6,217.1,27.7" style="fill:#FFFFFF;"/>
      <path d="M251.9,17
		c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1
		c-0.3,0.2-0.5,0.4-0.8,0.6c-0.2,0.2-0.4,0.5-0.7,0.7c-0.2,0.3-0.3,0.5-0.5,0.7c-0.1,0.3-0.3,0.5-0.4,0.7c0,0.2-0.2,0.2-0.2,0.4
		c0,0-0.1,0.1-0.1,0.1c-0.2,0.5-0.4,0.9-0.6,1.3c-0.1,0.3-0.1,0.6-0.3,0.9c-0.2,0.8-0.4,1.7-0.6,2.6c-0.2,1.1-0.3,2.3-0.3,3.6
		c-0.1,1.3-0.1,2.4,0,3.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.3,0.1,0.4c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.4,0,0.8,0.2,1.1c0,0.4,0.2,0.7,0.2,1.1c0,0.1,0.1,0.1,0.1,0.2c0,0.3,0.2,0.5,0.2,0.8c0.1,0.2,0.2,0.4,0.2,0.6
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0.2,0.3,0.3,0.5,0.5,0.8c0,0,0.1,0,0.1,0.1c0.2,0.4,0.5,0.7,0.8,1
		c0.2,0.1,0.3,0.3,0.5,0.4c0.1,0.2,0.4,0.2,0.5,0.4c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0,0.3,0,0.4,0.1c0.2,0,0.3,0,0.5,0.1
		c0.5,0.1,1.1,0,1.7,0c0.2-0.1,0.6-0.1,0.8-0.2c0.1,0,0.1-0.1,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.3
		c0.2-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.2-0.3,0.4-0.4c0.3-0.3,0.5-0.7,0.8-1c0.1-0.2,0.2-0.5,0.4-0.7c0-0.1,0.1-0.2,0.1-0.2
		c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.7,0.4-1c0.1-0.4,0.3-0.8,0.3-1.2c0.1-0.1,0.1-0.3,0.1-0.5c0.2-0.5,0.2-1.1,0.4-1.6
		c0.1-0.4,0.1-0.8,0.2-1.1c0.1-1.5,0.3-3.1,0.3-4.6c0-0.6-0.1-1.2-0.1-1.7c0-0.1-0.1-0.1-0.1-0.2c0-0.8-0.2-1.6-0.3-2.3
		c-0.1-0.3-0.2-0.6-0.2-0.9c-0.2-0.3-0.2-0.8-0.4-1.2c0,0,0-0.1,0-0.2c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.2-0.2-0.4-0.3-0.7
		c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.2-0.3-0.3-0.3-0.5c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.3-0.5-0.5-0.7-0.8c-0.3-0.3-0.6-0.4-0.9-0.7
		c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.7-0.1c-0.1,0-0.3-0.1-0.5-0.1
		c0,0,0,0,0,0C252.6,16.9,252.1,16.8,251.9,17 M253.3,15.9c1.3-0.1,2.5,0.1,3.6,0.2c0.1,0,0.1,0.1,0.2,0.1c0.5,0,0.9,0.2,1.3,0.3
		c0,0,0.1,0.1,0.2,0.1c0.4,0.1,0.7,0.2,1,0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1c0.2,0,0.2,0.1,0.4,0.1
		c0.2,0.2,0.6,0.2,0.8,0.4c0.2,0,0.3,0.2,0.5,0.3c0.1,0.2,0.4,0.2,0.6,0.4c0.1,0,0.1,0,0.2,0.1c0.3,0.2,0.6,0.5,0.9,0.7
		c0.3,0.2,0.5,0.6,0.8,0.8c0.3,0.4,0.7,0.8,0.9,1.3c0,0,0.1,0.1,0.1,0.1c0,0.2,0.2,0.3,0.2,0.5c0,0,0.1,0.1,0.1,0.1
		c0,0.2,0.2,0.3,0.2,0.6c0.2,0.2,0.2,0.6,0.4,0.9c0.1,0.2,0.1,0.5,0.2,0.7c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.1
		c0.1,0.3,0.1,0.6,0.2,0.8c0,0.1,0,0.2,0,0.4c0.1,0.2,0.1,0.5,0.2,0.7c0,0.5,0.1,1,0.1,1.5c0,0.1,0,0.1,0,0.2c0,0.8,0,1.6-0.1,2.4
		c0,0.2,0,0.5-0.1,0.7c0,0.1-0.1,0.1-0.1,0.2c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.8-0.2,1.1
		c0,0.1-0.1,0.1-0.1,0.1c0,0.2,0,0.5-0.2,0.6c-0.1,0.3-0.2,0.6-0.3,0.8c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.1
		c-0.1,0.3-0.2,0.4-0.3,0.7c-0.2,0.4-0.5,0.7-0.7,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.7-1,1.2-1.5,1.7c-0.4,0.3-0.8,0.6-1.2,1
		c-0.4,0.2-0.8,0.5-1.2,0.8c-0.3,0.1-0.6,0.3-0.9,0.5c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0.2-0.7,0.3-1,0.4
		c-0.3,0-0.6,0.2-0.9,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.1-0.9,0.2-1.3,0.3c-0.2,0.1-0.5,0.1-0.8,0.1c-1.2,0.2-2.9,0.2-4.3,0.2
		c-0.5-0.1-1.3-0.1-1.7-0.3c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.1-0.7-0.2-1-0.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1c-0.2,0-0.3-0.1-0.4-0.1
		c-0.2-0.2-0.6-0.2-0.8-0.4c-0.1,0-0.1,0-0.1,0c-0.1-0.1-0.4-0.1-0.4-0.3c-0.6-0.3-1.1-0.7-1.6-1.1c-0.3-0.3-0.5-0.5-0.8-0.8
		c-0.3-0.3-0.5-0.7-0.8-1c-0.1-0.2-0.2-0.4-0.4-0.6c0,0,0-0.1,0-0.1c-0.4-0.5-0.6-1.1-0.9-1.7c0-0.2-0.1-0.3-0.1-0.5
		c-0.2-0.4-0.2-0.9-0.4-1.4c0-0.3-0.1-0.7-0.2-0.9c0-0.3,0-0.5,0-0.8c-0.1-0.4,0-1-0.1-1.4c0-0.8,0-1.6,0.2-2.4
		c0.1-0.4,0.1-0.9,0.2-1.3c0.1-0.5,0.2-0.9,0.3-1.3c0-0.5,0.3-0.8,0.4-1.3c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2
		c0.2-0.3,0.3-0.8,0.5-1.1c0-0.1,0-0.1,0-0.2c0.2-0.4,0.5-0.7,0.7-1.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1
		c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.3,0.5-0.5,0.7-0.8c0.4-0.5,0.9-0.8,1.4-1.3c0.3-0.2,0.7-0.4,1-0.6
		c0.2-0.1,0.3-0.2,0.5-0.2c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3-0.2,0.5-0.2c0,0,0.1-0.1,0.1-0.1c0.5-0.2,0.9-0.4,1.3-0.5
		c0.4-0.1,0.8-0.3,1.2-0.4c0.1,0,0.1-0.1,0.2-0.1c0.3,0,0.5-0.1,0.8-0.1c0.2-0.1,0.5-0.1,0.7-0.2c0.5,0,1-0.1,1.6-0.2
		c0.1,0,0.1,0,0.2-0.1C252.3,15.9,252.8,15.9,253.3,15.9" style="fill:#FFFFFF;"/>
      <path d="M137.5,36.1
		c-1,1-2.3,2.1-4.3,2.7c-1.9,0.6-4,0.5-5.1-1c-1-1.4-0.5-4,0.8-5.6c1.3-1.6,2.9-2.4,4.3-2.9c2.5-1,4.7-1.3,4.7-1.3
		C137.9,29.2,137.6,35.1,137.5,36.1 M148.7,39.5c-0.3,0.1-1,0.3-1.6,0.4c-0.6,0.1-1.4,0.1-1.9-0.2c-0.4-0.3-0.7-0.9-0.6-3
		c0.1-2.1,0.4-7.7,0.5-10.3c0.1-2.7,0.4-5.2-0.9-7.3c-1.3-2.1-4.5-3.4-9.5-3.2c-2,0.1-3.5,0.2-4.8,0.5c-1.3,0.2-3,0.7-4.5,1.4
		s-2.8,1.9-3.2,3.1c-0.4,1.1-0.7,2.2,0.3,3.4c1,1.2,3.7,1.3,5.3,0.9c1.8-0.5,1.8-1,1.8-1c-0.1-0.9-0.1-1.1-0.3-2.4
		c-0.2-1.2,0.2-2.3,0.9-3.2c0.7-0.9,2.4-1.5,3.5-1.6c1.1-0.1,2.9,0.1,3.8,1.3s0.7,3.3,0.6,4.8c-0.1,1.5-0.2,3.7-0.2,3.7
		c-3.9,0.5-7.5,1.5-10.5,2.7c-2.4,1-5,2.8-6.5,5c-1.5,2.2-1.4,4.3-0.6,5.8c0.8,1.5,2.9,3,6.2,3c3.3,0.1,6.1-1.3,7.9-2.8
		c1.8-1.5,3-3.3,3-3.3c0,1.5-0.4,4.3,1.3,5.3c1.7,1,4.3,0.4,6.2-0.3c1.9-0.7,3.5-1.8,3.8-2.1C149.1,39.7,149.1,39.5,148.7,39.5"
            style="fill:#FFFFFF;"/>
      <path d="M99.8,60.4h1.9
		c0.4,0,0.8-0.1,1-0.2c0.2-0.1,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.1-0.6-0.2-1-0.2h-2v-0.9h1.7c0.4,0,0.7-0.1,0.9-0.2
		c0.2-0.1,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.1-0.5-0.2-0.9-0.2h-1.6V60.4z M98.6,61.3v-6.3h2.9c0.7,0,1.3,0.1,1.7,0.4
		c0.4,0.3,0.6,0.7,0.6,1.2c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.5,0.2-0.8,0.2l0.2-0.3c0.4,0,0.7,0.1,0.9,0.2
		c0.3,0.1,0.5,0.3,0.7,0.6c0.2,0.2,0.2,0.5,0.2,0.9c0,0.5-0.2,1-0.6,1.2c-0.4,0.3-1,0.4-1.8,0.4H98.6z"
            style="fill:#FFFFFF;"/>
    </g>
    <path class="st1"
          d="M108.5,59.9l0.3-0.9h3.4l0.3,0.9H108.5z M107.2,61.3l2.8-6.3h1.1l2.8,6.3h-1.2l-2.4-5.6h0.5l-2.4,5.6H107.2z"/>
    <rect class="st1" height="6.3" width="1.2" x="117.4" y="55.1"/>
    <polygon class="st1" points="123,61.3 123,55.1 123.9,55.1 127.8,59.9 127.4,59.9 127.4,55.1 128.5,55.1 128.5,61.3 127.6,61.3
	123.6,56.5 124.1,56.5 124.1,61.3 "/>
    <g>
      <path d="M134.8,61.4
		c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.8-0.3-1.1-0.5l0.4-0.9c0.2,0.2,0.6,0.4,0.9,0.5c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1
		c0.2-0.1,0.4-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.1-0.7-0.2
		c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3,0.1-0.7,0.3-0.9
		c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.3,1.4-0.3c0.4,0,0.7,0,1.1,0.1c0.4,0.1,0.7,0.2,1,0.4l-0.4,0.9c-0.3-0.2-0.6-0.3-0.9-0.4
		c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.3,0.2,0.5
		c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.3,0.5,0.5
		c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7C135.9,61.3,135.4,61.4,134.8,61.4"
            style="fill:#FFFFFF;"/>
      <path d="M140.8,62.7l0.5-2
		l0.2,0.7c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
		c0.1,0.1,0.2,0.3,0.2,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3l-0.5,1.3H140.8z"
            style="fill:#FFFFFF;"/>
      <path d="M153.1,61.4
		c-0.5,0-1-0.1-1.4-0.2s-0.8-0.3-1.1-0.5l0.4-0.9c0.2,0.2,0.6,0.4,0.9,0.5c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1
		c0.2-0.1,0.4-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.5-0.1-0.7-0.2
		c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3,0.1-0.7,0.3-0.9
		c0.2-0.3,0.5-0.5,0.8-0.7c0.4-0.2,0.8-0.3,1.4-0.3c0.4,0,0.7,0,1.1,0.1c0.4,0.1,0.7,0.2,1,0.4l-0.4,0.9c-0.3-0.2-0.6-0.3-0.9-0.4
		c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.3,0.2,0.5
		c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.4,0.3,0.5,0.5
		c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-0.8,0.7C154.2,61.3,153.7,61.4,153.1,61.4"
            style="fill:#FFFFFF;"/>
      <path d="M160.6,59.1l-0.5-0.6
		h1.9c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9S162.5,56,162,56h-1.9l0.5-0.6V59.1z M159.5,61.3v-6.3
		h2.6c0.6,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.2c0,0.5-0.1,0.9-0.3,1.2c-0.2,0.3-0.5,0.6-0.9,0.8
		c-0.4,0.2-0.9,0.3-1.4,0.3h-1.9l0.5-0.5v2.3H159.5z" style="fill:#FFFFFF;"/>
    </g>
    <path class="st1"
          d="M168.6,59.9L169,59h3.4l0.3,0.9H168.6z M167.3,61.3l2.8-6.3h1.1l2.8,6.3h-1.2l-2.4-5.6h0.5l-2.4,5.6H167.3z"/>
    <g>
      <path d="M184.5,61.4
		c-0.4,0-0.8-0.1-1.2-0.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.3,0.1-0.6,0.2-0.8s0.3-0.5,0.6-0.7
		c0.3-0.2,0.6-0.4,1.1-0.7c0.4-0.2,0.6-0.3,0.8-0.5c0.2-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2-0.1-0.3-0.2-0.4
		s-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3
		c0.1,0.1,0.3,0.3,0.4,0.5l3.3,3.2l-0.6,0.7l-3.6-3.4c-0.2-0.2-0.4-0.4-0.5-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5
		c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.6-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.2s0.4,0.3,0.6,0.5
		c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.6,0.6c-0.3,0.2-0.6,0.4-1.1,0.6c-0.3,0.2-0.6,0.3-0.8,0.5
		s-0.3,0.3-0.4,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.7,0.1
		c0.4,0,0.7-0.1,1-0.2c0.3-0.2,0.6-0.4,0.8-0.8c0.2-0.3,0.4-0.8,0.5-1.3l0.9,0.3c-0.1,0.6-0.3,1.1-0.6,1.6c-0.3,0.4-0.7,0.8-1.1,1
		C185.6,61.3,185.1,61.4,184.5,61.4" style="fill:#FFFFFF;"/>
    </g>
    <polygon class="st1" points="198.7,61.3 196,55.1 197.2,55.1 199.7,60.7 199,60.7 201.4,55.1 202.6,55.1 199.9,61.3 "/>
    <rect class="st1" height="6.3" width="1.2" x="206.1" y="55.1"/>
    <polygon class="st1" points="212.9,61.3 212.9,56 210.8,56 210.8,55.1 216.1,55.1 216.1,56 214,56 214,61.3 "/>
    <path class="st1"
          d="M219.4,59.9l0.3-0.9h3.4l0.3,0.9H219.4z M218.1,61.3l2.8-6.3h1.1l2.8,6.3h-1.2l-2.4-5.6h0.5l-2.4,5.6H218.1z"/>
    <polygon class="st1" points="228.4,61.3 228.4,55.1 229.5,55.1 229.5,60.3 232.8,60.3 232.8,61.3 "/>
    <rect class="st1" height="6.3" width="1.2" x="236.4" y="55.1"/>
    <polygon class="st1" points="243.2,61.3 243.2,56 241.1,56 241.1,55.1 246.4,55.1 246.4,56 244.4,56 244.4,61.3 "/>
    <path class="st1" d="M251.5,54.5l1.2-1.2h1.3l-1.6,1.2H251.5z M251,57.7h3.1v1H251V57.7z M251.1,60.3h3.5v1H250v-6.3h4.6v1h-3.4
	V60.3z"/>
  </svg>

</ng-template>
