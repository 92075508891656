<div
  class="relative mx-auto block w-full rounded-lg bg-white px-8 lg:px-14 py-8 lg:py-14 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">

  <button (click)="drawerService.close()"
          class="absolute top-1 right-1 inline-flex rounded-md p-1.5 text-gray-500 hover:text-bleuCaliceo-300 focus:outline-none focus:ring-1 focus:ring-bleuCaliceo-300 focus:ring-offset-2 focus:ring-offset-bleuCaliceo-100"
          type="button">
    <span class="sr-only">Dismiss</span>
    <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
    </svg>
  </button>
  @if (couponCartItem) {
    <!--Center title -->
    <h1 class="font-bold text-3xl md:text-4xl leading-tight text-bleuCaliceo-300 uppercase text-center">
      {{ 'claimGiftResultTitle' | translate }}
    </h1>
    <!-- Description -->
    <div class="ml-4 mt-4 font-bold text-xl md:text-2xl leading-tight text-bleuCaliceo-300 uppercase">
      {{ 'claimGiftContent' | translate }}
    </div>

    <app-cart-item-detail [(item)]="couponCartItem" [canDelete]="false"></app-cart-item-detail>

    <!--Big Button-->
    @if (nothingToDo) {
      <button appHoverGradient class="big-btn" (click)="drawerService.close()">
        {{ 'close' | translate }}
      </button>
    } @else {
      <button appHoverGradient class="big-btn" [appProgressClick]="addToCart">
        {{ 'addToCart' | translate }}
      </button>
    }
  } @else {
    <!--Center title -->
    <h1 class="font-bold text-3xl md:text-4xl leading-tight text-bleuCaliceo-300 uppercase text-center">
      {{ 'claimGift' | translate }}
    </h1>
    <!-- Description -->
    <p class="my-4 text-gray-500 font-semibold uppercase text-center whitespace-pre-line">
      {{ 'claimGiftDescription' | translate }}
    </p>


    <div>
      <div class="relative mt-2 rounded-md shadow-sm">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg viewBox="0 0 60 60" class="h-12 w-12 block m-auto object-cover">
            <defs>
              <style>.cls-claim {
                fill: none;
                stroke: #6b6b6b;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.76px;
              }</style>
            </defs>
            <path class="cls-claim fill-none stroke-2"
                  d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
          </svg>
        </div>

        <input
          [formControl]="code"
          class="block w-full rounded-lg border-0 px-6 py-5 pl-20 mt-5 font-medium text-md bg-beige text-gray-500"
          placeholder="XXXXXXX">
      </div>
    </div>

    <!--Big Button-->
    <button appHoverGradient class="big-btn" [disabled]="code.invalid" [appProgressClick]="addVoucher">
      {{ 'claimGift' | translate }}
    </button>
  }
</div>
